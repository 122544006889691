import { useState } from 'react'
// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Map01 from './../../images/map01.svg'
import Map02 from './../../images/map02.svg'
import Map03 from './../../images/map03.svg'
import Map04 from './../../images/map04.svg'

// Component
const Context = () => {

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
    setShowMenu3(false)
    setShowMenu4(false)
  }
  function showMenu3Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(true)
    setShowMenu4(false)
  }
  function showMenu4Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(true)
  }

  return (
    <div className="pages">
      
      <TitleBox
        bread = {'CONTEXT AND THE SCOPE'}
        title={'Country Offices with an active health and nutrition response'}
      />
      <article className="article_body min-h-450 h-full relative block">
        
        {showMenu1 && 
            <img className="w-full p-8 pr-12" src={Map01} />
        }
        {showMenu2 && 
            <img className="w-full p-8 pr-12" src={Map02} />
        }
        {showMenu3 && 
            <img className="w-full p-8 pr-12" src={Map03} />
        }
        {showMenu4 && 
            <img className="w-full p-8 pr-12" src={Map04} />
        }
        
        <p className="pl-6 pr-10 py-2 text-sm">Click to select</p>
        <div className="w-full flex flex-row items-stretch flex-wrap">
          
          {/* MENU 1 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-main_blue hover:bg-main_blue_90 text-white
          "
            onClick={showMenu1Handler}
          >
            <div className="flex-grow">Involved in the provision of PPE</div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 2 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
          "
            onClick={showMenu2Handler}
          >
            <div className="flex-grow">Involved in supporting MOH for the continuation of essential health and nutrition services</div>
            {showMenu2 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 3 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-l_green_hor_menu hover:bg-l_green_hor_menu_90 text-white
            "
              onClick={showMenu3Handler}
            >
            <div className="flex-grow">Involved in using innovations and digital technology in the health and nutrition response</div>
            {showMenu3 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 4 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-d_green_hor_menu hover:bg-d_green_hor_menu_90 text-white
            "
              onClick={showMenu4Handler}
            >
            <div className="flex-grow">Involved in HSS in the health and nutrition response to COVID-19</div>
            {showMenu4 ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
            }
          </div>
          
        </div>
        
        <button className="
          absolute map_z_info top-4 right-8
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
        >
          <span className="relative icon-info top-0.5"></span>
        </button>

      </article>

      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }

    </div>
  ) 
}

export default Context
