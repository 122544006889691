const Kazakhstan  = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>UNICEF Kazakhstan, jointly with UNICEF Ukraine, conducted TOT WS IPC and WASH in HCF; then UNICEF's partner "KAMEDA" Public Fund collected and assessed IPC and WASH data from 7 perinatal centers and 7 children's hospitals in 7 selected oblasts. The key findings were presented to the staff, management, and authorities at the local level across all 7 pilot regions. In January, IPC and WASH data of 14 health organizations were included in the database to provide further correlative analysis on the key findings of the external assessment. The results will inform policy recommendations to the managers of health organizations and to the local and national governments on the capacity building of the staff to ensure better health outcomes to pregnant women, mothers, and children in line with the "WHO/UNICEF: Water and Sanitation for Health Facility Improvement (WASH FIT)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
              <ul className="bullets">
                <li>UNICEF supported the Government in the development of the new Health Code for 2020-2025, validation of eMTCT status, the National Nutrition Strategy to address child overweight, as well as strengthening routine immunization. </li>
                <li>To improve IPC and WASH in HCF, UNICEF conducted an assessment in selected regions and capacitated local health service providers.</li>
                <li>To improve nutrition status and access to WASH in schools, UNICEF, jointly with WHO and MoH conducted country overweigh surveillance initiative (COSI) {'&'} WASH surveys in all regions and capacitated local service providers. </li>
                <li>With ECHO support, UNICEF procured 2,000 pulse oximeters and over 300,000 ampules of dexamethasone to support the Government's COVID-19 response.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Kazakhstan 
