// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/provision3.jpg'

// Component
const Provision3 = () => {

  return (
    <>
      <TitleBox
        bread = {'CHALLENGES AND THE WAY FORWARD'}
        title={'Provision of support to regulate school nutrition programs in the context of the pandemic'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Limited time for development of regulation on school nutrition during the time of pandemic</li>
          <li>There is no widespread compliance with health and safety regulations and unified standards.</li>
          <li>Closure of school resulted in abortion of school nutrition programs</li>
          <li>Lack of monitoring on school nutrition programs can be a risk during the opening of schools and their canteens</li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </>
  ) 
}

export default Provision3
