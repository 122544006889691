// Import Image
import Image from './../../images/albania.jpg'

// Component
const Albania = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>UNICEF continued to support the Institute of Public Health and the regional departments of health nationwide to consolidate the web-based data collection system for child nutrition monitoring for children under 5 years of age and adjust reporting in the context of COVID-19. Health providers in all Albania regions are using a standardized data collection system to monitor child growth and IYCF practices and flag out any emerging risks for child nutrition status in the context of COVID-19.</p>
        <p>Over 80 health professionals in 13 districts were supervised and coached in the correct data collection, analysis and reporting for child nutrition monitoring, as well as IYCF counselling in the context of COVID-19. </p>
        <img className="w-full" src={Image} />
      </div>
      <div className="bg-main_blue px-8 py-6 text-3xl text-white text-bold">
      5265 children under 5 years of age were reached with growth monitoring and data were registered in the child nutrition monitoring web-based system, 4842 mothers were provided advice on IYCF through the primary health care service providers.  
      </div>
    </article>
  ) 
}

export default Albania
