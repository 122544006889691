// Import Image
import Image from './../../images/serbia.jpg'

// Component
const Serbia = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Breastfeeding: The new virus caused significant dilemma's related to COVID-19 transmission and has largely influenced the organization of maternities and neonatal services (with the intention to separate mothers and babies to limit the risk of transmission). In the absence of clear guidelines, pediatric nurses in maternities and home visiting nurses were doubtful how to support mothers continue breastfeeding even if they are COVID-19 positive. With the timely availability of international guidelines on breastfeeding and COBVID-19, the National expert group (UNICEF being part of it) has developed the first National guidelines on delivery, breastfeeding and newborn care already in March (with additional revisions in July on COVID-19 positive mothers) and made them accessible to a larger population of health workers - through direct sharing and organization of webinars for perinatal professionals (nurses and doctors) and home visiting nurses. Additionally, with strong UNICEF support, the information has been shared through media, visual materials (posters and leaflets) prepared and distributed among vulnerable populations (Roma and refugees), and relevant articles also included in the Halo Beba mobile application of parents. Unfortunately, besides coverage/reach of professionals and parents, we do not have other data to document the outcome.</p>
        <p>Tele/video working with home visiting nurses: At the beginning of the COVID-19 pandemic with restricted movement, limited access to PPE, and increased fear of transmission, the work of home visiting nurses has been affected and left to individual primary health care centers to regulate which in some ended up being largely reduced. To address these issues, UNICEF gathered with IPH and MoH and selected representatives of HVNs and moved on to initiating support for Tele/video working in the selected primary health care centers. This initiative has mobilized the National IPH to develop guidelines for home visiting nurses during COVID-19, integrating IPC measured and criteria for Tele/video counselling, using the technical expertise of UNICEF and lessons learned from the ongoing initiative. These guidelines have been adopted and shared widely. Through initiative implemented in selected PHC, the capacity-building package and other resources for professionals and parents have been developed and a virtual exchange network among nurses created. This initiate has so far gathered and trained over 300 nurses (25% of the total number of nurses) who reached over 10,000 parents with quality Tele/video counselling. In the next period, UNICEF will work with the MoH and IPH to increase the number of PHCs and nurses covered and facilitate scaling up this modality of work throughout the country (according to the set criteria that are based on recognizing home visits as primary work modality of HVNs).</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Serbia
