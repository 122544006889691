const Achievements4 = () => {
  return (    
    <div className="article_content pl-6 pr-10">
      <p>The need to adjust to COVID-19 restrictions led to the implementation of strategies that are responsible for the strengthening of the Health systems, such as:</p>
      <ul className="bullets">
        <li>The introduction of online communication and consultation systems.</li>
        <li>The increase of provision of essential life-saving equipment.</li>
      </ul>	
    </div>
  ) 
}
export default Achievements4
