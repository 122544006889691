// Import Image
import Image from './../../images/romania.jpg'

// Component
const Romania = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>The Department of Public Health, Faculty of Political, Administrative and Communication Sciences, Babeș-Bolyai University Cluj-Napoca, in collaboration with the UNICEF Romania office, held a series of training sessions dedicated to community nurses between November 9, 2020, and December 18, 2020. and health mediators to increase the capacity to manage the COVID-19 pandemic at the community level.</p>
        <p>The courses covered various topics of interest, from up-to-date information on the SARS-VOC-2 virus, transmission, symptoms, and methods to prevent and treat COVID-19 to case management in the community and addressing cross-cutting issues such as disability, immunization, violence against children.</p>
        <p>The training sessions took place online, during two meetings of 2 hours each, scheduled depending on the availability of community nurses and trainers.</p>
        <img className="w-full" src={Image} />
      </div>
      <div className="bg-main_blue px-8 py-6 text-3xl text-white text-bold">
      A total of 144 sessions were delivered, totaling 288 hours, divided between the eight trainers. During these 288 hours, 1658 community nurses, health mediators, and county coordinators were trained.      </div>
    </article>
  ) 
}

export default Romania
