// Import Image
import Image from './../../images/montenegro.jpg'

// Component
const Montenegro = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Within the joint UN response to the emerging challenges of COVID-19 pandemic, UNICEF is leading the component on Risk Communication and Community Engagement (RCCE).</p>
        <p>In 2020, UNICEF’s risk communication and community engagement resulted in growing awareness and adherence to prevention and response measures regarding COVID-19. This involved the production of multimedia messages that were distributed and broadcasted in the mass media: 12 short messages by leading experts on COVID-19 were broadcasted on 2 TV stations daily (RTCG and PRVA). In addition, daily messages were produced and distributed though social networks: Facebook, Twitter, Instagram and YouTube.</p>
        <p>With the support of UNICEF and the British Embassy in Podgorica, two major national authorities on COVID-19, the Institute for Public Health and the Clinical Center of Montenegro strengthened their capacities for risk communication and community engagement response, which resulted in growing number of messages and information around COVID-19 for different target audiences. </p>
        <p>Furthermore, to address the needs of the vulnerable children, capacities of the institution that provides care for children without parental care in Montenegro, Children’s Home Mladost and its nationally available counselling service - the National Children’s Helpline were strengthened in cooperation with NGO Juventas.</p>
        <p>More than 200,000 people were reached with messaging on COVID-19 prevention and access to services through activities implemented by UNICEF Montenegro and aforementioned implementing partners. </p>
        <p>Some of the key results of the tracking survey conducted by IPSOS with the support of UNICEF and the British Embassy in Podgorica confirm high awareness and growing support and self-reported adherence to prevention measures by citizens. This report highlights changes recorded between May 2020 (which was set as a baseline for measuring change and impact of the project) and December:</p>
        <ul className="bullets noColumnBreak">
          <li>o	Frequent handwashing: from 97 per cent in May to 98 per cent in December 2020</li>
          <li>Talking while maintaining physical distance: from 76 per cent in May to 81 per cent in December 2020</li>
          <li>Avoiding hand shake: from 80 per cent in May to 85 per cent in December 2020</li>
          <li>Avoiding physical contact with family members: from 45 per cent in May to 54 per cent in December 2020</li>
          <li>Wearing masks indoor: from 81 per cent in May to 86 per cent in December 2020</li>
        </ul>
        <p>The findings were used to further generate interest of the media and the public and promote adherence in public communication.</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Montenegro
