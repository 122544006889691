import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
// IMPORT COMPONENTS
import NavItem from './NavItem'
import NavItemLink from './NavItemLink'
import NavSubItem from './NavSubItem'
// COMPONENT
const NavBar = ({ showNav, setShowNav }) => {
    
  // CONSTANTS
  const location = useLocation();
  const mainItem = location.pathname;
  const secItem = location.hash;

  console.log(mainItem);
  console.log(secItem);

  // RETURNING
  return (
    <div className={`
      absolute min-w-460 xs:min-w-0 w-80 xs:w-full bg-sec_blue z-10
      top-0 right-0 p-4 pt-12
      `
    }> 
    <nav className={`
        w-full py-3
        flex flex-col
        justify-center items-start
    `}
        role="navigation" aria-label="main navigation">
          <NavItemLink
            setShowNav ={ setShowNav }
            title={'CONTEXT AND THE SCOPE'}
            navActive={secItem === 'context-and-the-scope' ? true : false}
            linkTo={'/context-and-the-scope'}
          />
          <NavItemLink
            setShowNav ={ setShowNav }
            title={'ELEMENTS OF HEALTH AND NUTRITION RESPONSE TO COVID-19'}
            navActive={secItem === 'elements-of-unicef-health-and-nutrition-response-to-covid-19' ? true : false}
            linkTo={'/elements-of-unicef-health-and-nutrition-response-to-covid-19'}
          />

          <NavItemLink
            setShowNav ={ setShowNav }
            title={'ACHIEVEMENTS AND PROGRESS'}
            navActive={secItem === 'achievements-and-progress' ? true : false}
            linkTo={'/achievements-and-progress'}
          />
          
          {/* <NavItem
              title={'ACHIEVEMENTS AND PROGRESS'}
              navActive={mainItem === '/determinants-and-drivers-of-adequate-diets' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Protection and Prevention Equipment'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#availability'}
                  navSubActive={secItem === '#availability' ? true : false}
                  hashValue={'#availability'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Continuation of essential health and nutrition services including Immunization and IYCF in the context of COVID-19'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#access'}
                  navSubActive={secItem === '#access' ? true : false}
                  hashValue={'#access'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Innovations and use of digital technology'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#affordability'}
                  navSubActive={secItem === '#affordability' ? true : false}
                  hashValue={'#affordability'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Health System Strengthening for COVID-19'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#desirability-and-convenience'}
                  navSubActive={secItem === '#desirability-and-convenience' ? true : false}
                  hashValue={'#desirability-and-convenience'}
              />
          </NavItem> */}

          <NavItemLink
            setShowNav ={ setShowNav }
            title={'SUCCESS STORIES'}
            navActive={secItem === 'success-stories' ? true : false}
            linkTo={'/success-stories'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'CHALLENGES AND THE WAY FORWARD'}
            navActive={secItem === 'challenges-and-the-way-forward' ? true : false}
            linkTo={'/challenges-and-the-way-forward'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'LESSONS LEARNED'}
            navActive={secItem === 'lessons-learned' ? true : false}
            linkTo={'/lessons-learned'}
          />
      </nav> 
  
    </div>
  )
}
// PROPTYPES
NavBar.propTypes = {
  showNav: PropTypes.bool.isRequired,
}
// EXPORT
export default NavBar
