// Import Components
import Breadcrumbs from './Breadcrumbs'
import PageTitle from './PageTitle'
// Component
const TitleBox = ({bread, title}) => {
  return (
    <div className="title_box pl-6 pr-10 pt-16">
        <Breadcrumbs breadcrumb= { bread } />
        <PageTitle title={ title } /> 
    </div>
  ) 
}
export default TitleBox