import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

// Component
const Chart02 = () => {

  const topValue = 1000000;
  const items = React.useMemo( () => [
    { 
      id: 1,
      country: 'Albania',
      classItem1: 'albania_1',
      classItem2: 'albania_2',
      target: 2000,
      reached: 10625,
      per: '531%',
      target_p: (2000 * 100) / topValue,
      reached_p: (10625 * 100) / topValue,
      label_per: (10625 * 100) / topValue,
    },
    { 
      id: 2,
      country: 'Armenia',
      classItem1: 'armenia_1',
      classItem2: 'armenia_2',
      target: 6000,
      reached: 6200,
      per: '103%',
      target_p: (6000 * 100) / topValue,
      reached_p: (6200 * 100) / topValue,
      label_per: (6200 * 100) / topValue,
    },
    { 
      id: 3,
      country: 'Azerbaijan',
      classItem1: 'azerbaijan_1',
      classItem2: 'azerbaijan_2',
      target: 60000,
      reached: 41290,
      per: '69%',
      target_p: (60000 * 100) / topValue,
      reached_p: (41290 * 100) / topValue,
      label_per: (60000 * 100) / topValue,
    },
    { 
      id: 4,
      country: 'Bosnia and Herzegovina',
      classItem1: 'bosnia_1',
      classItem2: 'bosnia_2',
      target: 8000,
      reached: 9562,
      per: '120%',
      target_p: (8000 * 100) / topValue,
      reached_p: (9562 * 100) / topValue,
      label_per: (9562 * 100) / topValue,
    },
    { 
      id: 5,
      country: 'Bulgaria',
      classItem1: 'bulgaria_1',
      classItem2: 'bulgaria_2',
      target: 3000,
      reached: 3500,
      per: '117%',
      target_p: (3000 * 100) / topValue,
      reached_p: (3500 * 100) / topValue,
      label_per: (3500 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Croatia',
      classItem1: 'croatia_1',
      classItem2: 'croatia_2',
      target: 25074,
      reached: 28828,
      per: '115%',
      target_p: (25074 * 100) / topValue,
      reached_p: (28828 * 100) / topValue,
      label_per: (28828 * 100) / topValue,
    },
    { 
      id: 8,
      country: 'Kazakhstan',
      classItem1: 'kazakhstan_1',
      classItem2: 'kazakhstan_2',
      target: 50000,
      reached: 160000,
      per: '320%',
      target_p: (50000 * 100) / topValue,
      reached_p: (160000 * 100) / topValue,
      label_per: (160000 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'Kosovo',
      classItem1: 'kosovo_1',
      classItem2: 'kosovo_2',
      target: 50000,
      reached: 64583,
      per: '129%',
      target_p: (50000 * 100) / topValue,
      reached_p: (64583 * 100) / topValue,
      label_per: (64583 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'Macedonia',
      classItem1: 'macedonia_1',
      classItem2: 'macedonia_2',
      target: 10000,
      reached: 10300,
      per: '103%',
      target_p: (10000 * 100) / topValue,
      reached_p: (10300 * 100) / topValue,
      label_per: (10300 * 100) / topValue,
    },
    { 
      id: 11,
      country: 'Moldova',
      classItem1: 'moldova_1',
      classItem2: 'moldova_2',
      target: 17000,
      reached: 17000,
      per: '100%',
      target_p: (17000 * 100) / topValue,
      reached_p: (17000 * 100) / topValue,
      label_per: (17000 * 100) / topValue,
    },
    { 
      id: 12,
      country: 'Republic of Kyrgyzstan',
      classItem1: 'kyrgyzstan_1',
      classItem2: 'kyrgyzstan_2',
      target: 300000,
      reached: 260658,
      per: '87%',
      target_p: (300000 * 100) / topValue,
      reached_p: (260658 * 100) / topValue,
      label_per: (300000 * 100) / topValue,
    },
    { 
      id: 13,
      country: 'Republic of Montenegro',
      classItem1: 'montenegro_1',
      classItem2: 'montenegro_2',
      target: 7000,
      reached: 448,
      per: '6%',
      target_p: (7000 * 100) / topValue,
      reached_p: (448 * 100) / topValue,
      label_per: (7000 * 100) / topValue,
    },
    { 
      id: 16,
      country: 'Romania',
      classItem1: 'romania_1',
      classItem2: 'romania_2',
      target: 100000,
      reached: 947339,
      per: '947%',
      target_p: (100000 * 100) / topValue,
      reached_p: (947339 * 100) / topValue,
      label_per: (947339 * 100) / topValue,
    },
    { 
      id: 14,
      country: 'Serbia',
      classItem1: 'serbia_1',
      classItem2: 'serbia_2',
      target: 7500,
      reached: 21092,
      per: '281%',
      target_p: (7500 * 100) / topValue,
      reached_p: (21092 * 100) / topValue,
      label_per: (21092 * 100) / topValue,
    },
    { 
      id: 15,
      country: 'Tajikistan',
      classItem1: 'tajikistan_1',
      classItem2: 'tajikistan_2',
      target: 683476,
      reached: 450000,
      per: '66%',
      target_p: (683476 * 100) / topValue,
      reached_p: (450000 * 100) / topValue,
      label_per: (683476 * 100) / topValue,
    }
  ])

  
  const [showTarget, setShowTarget] = useState(true);
  const showTargetHandler = () => setShowTarget(!showTarget);
  const [showReached, setShowReached] = useState(true);
  const showReachedHandler = () => setShowReached(!showReached);

  return (
    <>
      <div className="article_content pl-6 pr-10 mt-16">
        <h1 className="text-xl font-bold text-main_blue">Number of caregivers of children (0-23 months) reached with messages on breastfeeding in the context of COVID-19</h1>
        <p className="py-2 text-sm">* Achieved %</p>
        <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars relative">
                  
                  {showTarget && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#00a0e9",
                          width: `${row.target_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem1}
                      ></a>
                      <ReactTooltip id={row.classItem1} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.target}</p>
                      </ReactTooltip>
                    </>
                  }

                  {showReached && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#39419a",
                          width: `${row.reached_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem2}
                      ></a>
                      <ReactTooltip id={row.classItem2} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.reached}</p>
                      </ReactTooltip>
                    </>
                  }

                  <div className="absolute font-bold text-sm" style={{ left:`${row.label_per + 2}%`, top: "6px" }}>{ row.per }*</div>

                </div>
              </div>
            )
          })}

        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div><span>200000</span></div>
            <div className="sm:hidden"><span>400000</span></div>
            <div><span>600000</span></div>
            <div className="sm:hidden"><span>800000</span></div>
            <div><span>1000000</span></div>
          </div>
        </div>
      </div>
      </div>

      {/* SORT CHECKBOXES */}
      <p className="pl-6 pr-10 py-2 text-sm">Click to select/deselect</p>
      <div className="w-full flex flex-row items-stretch flex-wrap">

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-main_blue hover:bg-main_blue_90 text-white
        "
          onClick={showTargetHandler}
        >
          <div className="flex-grow">Sum of Target</div>
          {showTarget ? 
            <span className="icon-circle-filled px-3 text-2xl"></span> :
            <span className="icon-circle-outline px-3 text-2xl"></span>
          }
        </div>

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
        "
          onClick={showReachedHandler}
        >
          <div className="flex-grow">Number Reached</div>
          {showReached ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
          }
        </div>
      
      </div>
      
    </>
  )
}

export default Chart02 
