import { VectorMap } from "react-jvectormap";
import { useState } from 'react'
// Component
const Map02 = ({ RegionClick }) => {

  var mapData = {

    AM: 0, // Armenia
    AZ: 1, // Azerbaijan
    GE: 2, // Georgia
    HR: 3, // Croatia
    KG: 4, // Kyrgyzstan
    KZ: 5, // Kazakhstan
    MD: 6, // Moldova
    ME: 7, // Montenegro
    MK: 8, // Macedonia
    RS: 9, // Serbia
    TJ: 10, // Tajikistan
    TM: 11, // Turkmenistan
    UZ: 12 , // Uzbekistan
    XK: 13, // Kosovo
  };

  var mapDataDetails = [
    {
      country: 'Armenia',	v1: '12,000', v2:	'10,000'
    },
    {
      country: 'Azerbaijan',	v1: '135,800', v2:	'n/a'
    },
    {
      country: 'Georgia',	v1: '45,062', v2:	'38,683'
    },
    {
      country: 'Croatia',	v1: '80,000', v2:	'n/a'
    },
    {
      country: 'Kyrgyzstan',	v1: '104,873 ', v2:	'218,647' 
    },
    {
      country: 'Kazakhstan',	v1: '299,517', v2:	'509,285' 
    },
    {
      country: 'Moldova',	v1: '37,696', v2:	'34,136 '
    },
    {
      country: 'Montenegro',	v1: '7,215', v2:	'5914'
    },
    {
      country: 'North Macedonia',	v1: '21,000', v2:	'n/a'
    },
    {
      country: 'Serbia',	v1: '63,800', v2:	'28,072'
    },
    {
      country: 'Tajikistan',	v1: '253,212', v2:	'177,773 '
    },
    {
      country: 'Turkmenistan',	v1: '110,000', v2:	'n/a'
    },
    {
      country: 'Uzbekistan',	v1: '654,720', v2: '565,076 '
    },
    {
      country: 'Kosovo',	v1: '20,000', v2:	'n/a'
    },
  ];
  
  const series = {
    regions: [
      {
        values: mapData,
        scale: ["#808080", "#808081"],
      }
    ]
  }
  
  const RegionTipShow = (e, el, code) => {
    if (mapData[code] >= 0) {
      el.html(
        '<h1>' + mapDataDetails[mapData[code]].country + '</h1>' +
        '<p>TOTAL PLANNED FOR 2020</p>' +
        '<p class="planned">' + mapDataDetails[mapData[code]].v1 + '</p><' +
        '<p>TOTAL ACHIEVED FOR 2020</p>' +
        '<p class="achieved">' + mapDataDetails[mapData[code]].v2 + '</p></div>'
      );
    } else {
      el.html('<h4>' + el.html() + '</h4>');
    }
  }

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  return (
    <>
      <h1 className="text-xl font-bold text-main_blue mt-16 px-6">Number of children vaccinated against Measles in 2020</h1>
      <p className="py-2 text-sm px-6">Roll the mouse over the country to see details</p>
      
      <div className="w-full h-80 relative">
        <button className="
        absolute map_z_info top-2 right-2
        w-6 h-6 rounded-full ani03s
        bg-txt_black_1 text-white text-sm hover:bg-gray
        "
        onClick={() => setShow(true)}
        >
          <span className="relative icon-info top-0.5"></span>
        </button>
        <VectorMap map={'world_mill'}
          backgroundColor="#f1f1f1"
          useRef="map"
          containerStyle={{
              width: '100%',
              height: '100%'
          }}
          containerClassName="map"
          regionStyle={{
            initial: {
              fill: "#c6c6c6",
              "stroke-width" : 0.1,
              "stroke-opacity" : 0.2,
            },
            hover: {
              fill: "#00aeef",
              "stroke-width" : 0.1,
              "stroke-opacity" : 0.2,
            }
          }}
          series={series}
          onRegionTipShow = {RegionTipShow}
          onRegionClick = { RegionClick }
          focusOn = {{scale: 2, x: 0.6, y: 0.4, animate: false}}
        />
      </div>
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </>
  ) 
}

export default Map02
