import { useEffect, useState, useRef } from "react";
import { Link} from "react-router-dom";
// Import Logo
import Logo from '../images/logo_unicef.svg'
// Import Components
import BtnNav from './BtnNav'
import BtnNavClose from './BtnNavClose'
import NavBar from './NavBar'
// Component
const HeaderCont = () => {
  
  // CONSTANTS
  const node = useRef();
  const [showNav, setShowNav] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowNav(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
    document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      <div className="pl-6 pr-10 pt-4 w-56 absolute z-10">
        <Link to="/"><img className="max-h-6" src={Logo} alt="Unicef logo" /></Link>
      </div>

      <div className="top-12 left-6 border-4 border-main_blue p-2 w-44 absolute">
        <div className="text-white text-3xl font-bold">ECARO</div>
        <div className="text-white">Health and Nutrition</div>
        <div className="text-white">Response to</div>
        <div className="text-white text-3xl font-bold">COVID-19</div>
        <div className="text-main_blue text-xs font-bold pt-1">2020 PROGRESS REVIEW</div>
      </div>
        
      <div className="absolute top-4 right-8 z-30">
          {showNav &&
            <BtnNav setShowNav = { setShowNav } />
          }
          {!showNav &&
            <BtnNavClose setShowNav = { setShowNav } />
          }
      </div>
      <div ref={node} className="absolut w-full h-0">
        { showNav && 
          <NavBar 
            showNav={ showNav }
            setShowNav={ setShowNav }
          /> 
        }
      </div>
    </>
  )
}
// Export
export default HeaderCont
