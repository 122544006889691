import { useState } from 'react'
// Page Contents
import Chart01 from './Chart01'
import Chart02 from './Chart02'
import Chart03 from './Chart03'
import Maps from './Maps'
// Component
const Achievements2 = () => {

  const [showSelectMenu, setShowSelectMenu] = useState(false);
  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu3Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(true)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu4Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(true)
    setShowSelectMenu(false)
  }

  return (    
    <>
      <div className="
        w-96 h-12 absolute p-2 pl-1 block ml-6
        bg-main_blue text-white hover:text-sec_blue
        text-xl cursor-pointer ani03s font-bold z-20
      "
        onClick={e => setShowSelectMenu(!showSelectMenu)}
      >
        Select
        { showSelectMenu ?
          <span className="icon-close absolute top-3 right-2"></span> :
          <span className="icon-down absolute top-3 right-2"></span>
        }
      </div>

      {showSelectMenu &&
        <div className="flex flex-col bg-white border border-main_blue p-4 pr-0 mt-12 w-96 z-10 left-6 absolute">
          <div className="flex items-center pb-3">
            <div 
              className={`${showMenu1 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu1Handler}
              >
                Number of children and women receiving essential healthcare through UNICEF supported community health workers and health facilities.
            </div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center pb-3">
            <div
              className={`${showMenu2 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu2Handler}
              >
                Number of caregivers of children (0-23 months) reached with messages on breastfeeding in the context of COVID-19 
            </div>
            {showMenu2 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center pb-3">
            <div
              className={`${showMenu3 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu3Handler}
              >
              Number of people reached with critical WASH supplies (including hygiene items) and services
            </div>
            {showMenu3 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center">
            <div
              className={`${showMenu4 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu4Handler}
              >
              Innovations in HSS in the Health and nutrition response to COVID-19
            </div>
            {showMenu4 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
        </div>
      }

      {showMenu1 && <Chart01/>}
      {showMenu2 && <Chart02/>}
      {showMenu3 && <Chart03/>}
      {showMenu4 && <Maps/>}
        
    </>
  ) 
}
export default Achievements2
