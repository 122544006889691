import PropTypes from 'prop-types'

const Breadcrumbs = ({ breadcrumb }) => {
    return (
        <div className="border border-main_blue px-2 py-1 text-txt_black_1 text-base inline font-bold uppercase">
            { breadcrumb }
        </div>
    )
}

Breadcrumbs.propTypes = {
    breadcrumb: PropTypes.string.isRequired,
}

export default Breadcrumbs
