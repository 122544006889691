// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/lessons.jpg'

// Component
const Lessons = () => {

  return (
    <div className="pages">
      <TitleBox 
        bread = {'LESSONS LEARNED'}
        title = {'Lessons learned'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Importance of evidence-based plan and forecasting.</li>
          <li>Timely and immediate response to the crisis.</li>
          <li>Effective cooperation with government and coordination with UN agencies as key to success.</li>
          <li>Working with national partners, to insist on system strengthening interventions that build long term resilience.</li>
          <li>Having market assessments in place to understand the market dynamics and having prior agreement with the MoHSPP on required approvals and bureaucratic processes to facilitate urgent procurement.</li>
          <li>Having flexibility and adaptability to the new requirements for delivery of support and for interventions using technologies for conducting capacity building activities to be fast in supporting the system for COVID response.</li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </div>
  ) 
}

export default Lessons
