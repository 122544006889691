import { useState } from 'react'
// Page Contents
import Table03_01 from './Table03_01'
import Table03_02 from './Table03_02'
import Table03_03 from './Table03_03'
import Table03_04 from './Table03_04'
// Component
const Achievements3 = () => {

  const [showSelectMenu, setShowSelectMenu] = useState(false);

  const [showMain, setShowMain] = useState(true);
  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu3Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(true)
    setShowMenu4(false)
    setShowSelectMenu(false)
  }
  function showMenu4Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(true)
    setShowSelectMenu(false)
  }

  return (    
    <div className="article_content pl-6 pr-10">
      <div className="
        w-96 h-12 absolute p-2 pl-1 block
        bg-main_blue text-white hover:text-sec_blue
        text-xl cursor-pointer ani03s font-bold z-20
      "
        onClick={e => setShowSelectMenu(!showSelectMenu)}
      >
        Select

        { showSelectMenu ?
          <span className="icon-close absolute top-3 right-2"></span> :
          <span className="icon-down absolute top-3 right-2"></span>
        }
      </div>

      { showSelectMenu &&
        <div className="flex flex-col bg-white border border-main_blue p-4 pr-0 mt-12 w-96 z-10 absolute">
          <div className="flex items-center pb-3">
            <div 
              className={`${showMenu1 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu1Handler}
              >
              Innovation in the Provision of PPE
            </div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center pb-3">
            <div
              className={`${showMenu2 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu2Handler}
              >
              Innovation in supporting MOH for the continuation of essential health and nutrition services
            </div>
            {showMenu2 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center pb-3">
            <div
              className={`${showMenu3 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu3Handler}
              >
              Innovations and digital technology in health and nutrition response
            </div>
            {showMenu3 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
          <div className="flex items-center">
            <div
              className={`${showMenu4 && 'font-bold'} hover:text-main_blue cursor-pointer ani03s text-sm leading-5 flex-grow`}
              onClick={showMenu4Handler}
              >
              Innovations in HSS in the Health and nutrition response to COVID-19
            </div>
            {showMenu4 ? 
              <span className="icon-circle-filled px-3 text-2xl text-main_blue"></span> :
              <span className="icon-circle-outline px-3 text-2xl text-main_blue"></span>
            }
          </div>
        </div>
      }

      {showMenu1 && <Table03_01/>}
      {showMenu2 && <Table03_02/>}
      {showMenu3 && <Table03_03/>}
      {showMenu4 && <Table03_04/>}
        
    </div>
  ) 
}
export default Achievements3
