// IMPORT BACKGROUND
import Background from './../images/back2.jpg'
// Import Components
import HeaderCont from './HeaderCont'
// Component
const Header2 = () => {
  return (
 
    <header 
      className="
        inline w-full min-h-180 max-h-180 relative
        bg-no-repeat bg-cover bg-center
      "
      style={{backgroundImage: 'url(' + Background + ')'}}
    >
      <div className="bg-black_70 h-full w-full absolute"></div>
      <HeaderCont />
      <div className="absolute left-52 top-24 text-left">
        <h1 className="text-base text-main_blue">Our mandate:</h1>
        <h1 className="text-base text-white">no child left</h1>
        <h1 className="text-base text-white">behind</h1>
      </div>
    </header>
    
  )
}
// Export
export default Header2
