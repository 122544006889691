// Import Image
import Image from './../../images/turkey.jpg'

// Component
const Turkey  = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>The MoH has led COVID-19 response in Turkey.  They have requested limited contribution from UN Agencies, including WHO and UNICEF. Pandemic is a very dynamic process and depends on the stage Government of Turkey and MoH announced many precautional measures. Simultaneously, there is a considerable amount of the refugee population that don't have enough knowledge to follow Turkish-based announcements and COVID19 related messages. UNICEF has taken an important role in transferring general messages to the refugee population in their language. UNICEF used all appropriate channels to reach the refugee population, including social media and RapidPro, SMS messages.</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Turkey 
