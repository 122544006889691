const Turkmenistan = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Enabling the continuation of uninterrupted immunization services is a success for the CO and the country. During the reporting period, a neighbouring country has faced another emergency of a polio outbreak. With the country's request, UNICEF supported the country with the requested number of the polio campaign in bordering areas as a prevention measure.</p>
      </div>
    </article>
  ) 
}

export default Turkmenistan
