import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

// Component
const Chart01 = () => {

  const topValue = 3000000;
  const items = React.useMemo( () => [
    { 
      id: 4,
      country: 'Bosnia and Herzegovina',
      classItem1: 'bosnia_1',
      classItem2: 'bosnia_2',
      target: 5000,
      reached: 10232,
      per: '205%',
      target_p: (5000 * 100) / topValue,
      reached_p: (10232 * 100) / topValue,
      label_per: (10232 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Croatia',
      classItem1: 'croatia_1',
      classItem2: 'croatia_2',
      target: 140,
      reached: 183,
      per: '131%',
      target_p: (140 * 100) / topValue,
      reached_p: (183 * 100) / topValue,
      label_per: (183 * 100) / topValue,
    },
    { 
      id: 7,
      country: 'Georgia',
      classItem1: 'georgia_1',
      classItem2: 'georgia_2',
      target: 50000,
      reached: 52000,
      per: '104%',
      target_p: (50000 * 100) / topValue,
      reached_p: (52000 * 100) / topValue,
      label_per: (52000 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'Kosovo',
      classItem1: 'kosovo_1',
      classItem2: 'kosovo_2',
      target: 50000,
      reached: 35304,
      per: '71%',
      target_p: (50000 * 100) / topValue,
      reached_p: (35304 * 100) / topValue,
      label_per: (50000 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'Rep of Uzbekistan',
      classItem1: 'uzbekistan_1',
      classItem2: 'uzbekistan_2',
      target: 2619840,
      reached: 2926267,
      per: '112%',
      target_p: (2619840 * 100) / topValue,
      reached_p: (2926267 * 100) / topValue,
      label_per: (2926267 * 100) / topValue,
    },
    { 
      id: 12,
      country: 'Republic of Kyrgyzstan',
      classItem1: 'kyrgyzstan_1',
      classItem2: 'kyrgyzstan_2',
      target: 195000,
      reached: 164200,
      per: '84%',
      target_p: (195000 * 100) / topValue,
      reached_p: (164200 * 100) / topValue,
      label_per: (195000 * 100) / topValue,
    },
    { 
      id: 13,
      country: 'Republic of Montenegro',
      classItem1: 'montenegro_1',
      classItem2: 'montenegro_2',
      target: 8000,
      reached: 7812,
      per: '98%',
      target_p: (8000 * 100) / topValue,
      reached_p: (7812 * 100) / topValue,
      label_per: (8000 * 100) / topValue,
    },
    { 
      id: 16,
      country: 'Romania',
      classItem1: 'romania_1',
      classItem2: 'romania_2',
      target: 230512,
      reached: 258127,
      per: '112%',
      target_p: (230512 * 100) / topValue,
      reached_p: (258127 * 100) / topValue,
      label_per: (258127 * 100) / topValue,
    },
    { 
      id: 14,
      country: 'Serbia',
      classItem1: 'serbia_1',
      classItem2: 'serbia_2',
      target: 10000,
      reached: 15948,
      per: '159%',
      target_p: (10000 * 100) / topValue,
      reached_p: (15948 * 100) / topValue,
      label_per: (15948 * 100) / topValue,
    },
    { 
      id: 15,
      country: 'Tajikistan',
      classItem1: 'tajikistan_1',
      classItem2: 'tajikistan_2',
      target: 580000,
      reached: 778553,
      per: '134%',
      target_p: (580000 * 100) / topValue,
      reached_p: (778553 * 100) / topValue,
      label_per: (778553 * 100) / topValue,
    },
  ])

  const [showTarget, setShowTarget] = useState(true);
  const showTargetHandler = () => setShowTarget(!showTarget);
  const [showReached, setShowReached] = useState(true);
  const showReachedHandler = () => setShowReached(!showReached);

  return (
    <>
      <div className="article_content pl-6 pr-10 mt-16">
        <h1 className="text-xl font-bold text-main_blue">Number of children and women receiving essential healthcare through UNICEF supported community health workers and health facilities.</h1>
        <p className="py-2 text-sm">* Achieved %</p>
        <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars relative">

                  {showTarget && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#00a0e9",
                          width: `${row.target_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem1}
                      ></a>
                      <ReactTooltip id={row.classItem1} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.target}</p>
                      </ReactTooltip>
                    </>
                  }

                  {showReached && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#39419a",
                          width: `${row.reached_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem2}
                      ></a>
                      <ReactTooltip id={row.classItem2} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.reached}</p>
                      </ReactTooltip>
                    </>
                  }

                  <div className="absolute font-bold text-sm" style={{ left:`${row.label_per + 2}%`, top: "6px" }}>{ row.per }*</div>

                </div>
              </div>
            )
          })}

        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>500000</span></div>
            <div><span>1000000</span></div>
            <div className="sm:hidden"><span>1500000</span></div>
            <div><span>2000000</span></div>
            <div className="sm:hidden"><span>2500000</span></div>
            <div><span>3000000</span></div>
          </div>
        </div>
      </div>
      </div>

      {/* SORT CHECKBOXES */}
      <p className="pl-6 pr-10 py-2 text-sm">Click to select/deselect</p>
      <div className="w-full flex flex-row items-stretch flex-wrap">

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-main_blue hover:bg-main_blue_90 text-white
        "
          onClick={showTargetHandler}
        >
          <div className="flex-grow">Sum of Target</div>
          {showTarget ? 
            <span className="icon-circle-filled px-3 text-2xl"></span> :
            <span className="icon-circle-outline px-3 text-2xl"></span>
          }
        </div>

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
        "
          onClick={showReachedHandler}
        >
          <div className="flex-grow">Number Reached</div>
          {showReached ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
          }
        </div>
      
      </div>
      
    </>
  )
}

export default Chart01 
