const Table03_02 = () => {
  return (    
    <table className="allBorders w-full border xs:text-xs text-left mt-16">
      <thead className="text-white bg-main_blue">
        <tr>
            <th className="text-xl">Country Office</th>
            <th className="text-xl">Innovation in supporting MOH for the continuation of essential health and nutrition services</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Uzbekistan</td><td>Introduction of a distance learning platform for health care providers.</td>
        </tr>
        <tr>
          <td>Croatia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Albania</td><td>Planning of online capacity development activities and innovative ways of remote coaching networks.</td>
        </tr>
        <tr>
          <td>Kyrgyzstan</td><td>Supporting MOH in transferring "Young child Home visiting services by primary health care providers" and pregnant women assisted self-HIV testing, from face to face to the WhatsApp mode.</td>
        </tr>
        <tr>
          <td>North Macedonia</td><td>Digitalization of recording and reporting forms for home visitors (ongoing).</td>
        </tr>
        <tr>
          <td>Montenegro</td><td>CO took part in virtual South-East Europe Health Network Conference to present finding of Root Cause Analysis of Low Immunization Coverage in Montenegro and advocated, on a various occasion, for more attention to be paid to routine immunization during the pandemic, thanks to which significant donor funds were mobilized in late 2020 for 2021 and 2022.</td>
        </tr>
        <tr>
          <td>Tajikistan</td><td>CO consultants worked remotely, facilitated training workshops, and conducted interviews/assessments by phone and other online platforms with local experts' support. </td>
        </tr>
        <tr>
          <td>Armenia</td><td>Online sessions for health providers were new for the country.</td>
        </tr>
        <tr>
          <td>Moldova</td><td>
          <p>Coordination with other UN agencies (WHO, UNFPA, UNAIDS) and MHLSP to avoid duplication and overwhelm of health staff.</p>
          <p>UNICEF used in parallel the aLive platform to inform the target group of the population on different health subjects: vaccination, nutrition, breastfeeding, COVID19 prevention.</p>
          <p>UNICEF also used the UReprt tool to learn and to assess the perception of the young population on the above-mentioned issues that informed our programming, and the result of U reports also were shared with stakeholders and decision-makers.</p>
          </td>
        </tr>
        <tr>
          <td>Ukraine</td><td>Within the frame of south-south cooperation with Kazakhstan CO, two online 14-days workshops on IPC/WASH and UPHV were organized for Kazakhstan and Ukrainian health workers. </td>
        </tr>
        <tr>
          <td>Turkmenistan</td><td>CO supported the country's efforts in enabling the continuation of routine immunization services through SD support with charter flights.</td>
        </tr>
        <tr>
          <td>Bosnia and Herzegovina</td><td>
            <p>UNICEF, in collaboration with the Ministries of Health and PHIs continued promoting an innovative 'Immunization App' during 2020. The app provides parents/caregivers with information on when the next vaccination is due and the closest health centre to make an appointment.</p>
            <p>UNICEF and PHIs also partnered with a local NGO to operate a dedicated blog to provide parents/caregivers with evidence-based facts on vaccines in user-friendly language.</p>
</td>
        </tr>
        <tr>
          <td>Serbia</td><td>
          <p>Tele/video counseling and strengthening capacities of institutions and professionals to support parents in this process – the focus was given top home visiting nurses and Roma health mediators, and early childhood intervention professionals. </p>
          <p>Working on engaging other digital innovative approaches - introducing messaging system between health institutions to strengthen continuity of care and more accessible follow-up and exchange. </p>
</td>
        </tr>
        <tr>
          <td>Belarus</td><td>n/a</td>
        </tr>
        <tr>
          <td>Turkey</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kosovo</td><td>Training of Health workers on digital skills. The training was provided in facilities by demonstrating the use of technology in the provision of MCH services. </td>
        </tr>
        <tr>
          <td>Georgia</td><td>UNICEF applied online platforms for capacity building interventions</td>
        </tr>
        <tr>
          <td>Azerbaijan</td><td>As a short-time solution, the services were shifted to an online format with the participation of leading professionals in specific areas of expertise. After negotiation, some PHC services were resumed with all precautions and limits to patients' flow.</td>
        </tr>
        <tr>
          <td>Bulgaria CO</td><td>n/a</td>
        </tr>
        <tr>
          <td>Romania</td><td>The Office is partnering with the Department of Public Health from Babes Boyal University to mobilize and speed up the implementation. </td>
        </tr>
        <tr>
          <td>Kazakhstan</td><td>External quality assessment of infection prevention control and WASH through the integration of The IPC Assessment Framework Tool (IPCAF) and Water and Sanitation for Health Facility Improvement Tool (WASH FIT).</td>
        </tr>
      </tbody>
    
    </table>
  ) 
}
export default Table03_02