const Kyrgyzstan = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Ministries of Health, education, social, protection, Emergency Situation.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Procurement PPE included 248, 726 masks, 75, 404 coveralls, 119, 050 (pcs) of boot covers, 5, 776 goggles, 2,000 pairs of boots, 362,792 pieces of gloves, 1,534 face shields, and 1,200 medical gowns.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>WhatsApp communication's operationalization as an alternative way of child consultation during such conditions as limited access to the family doctors, high prevalence of the coronavirus cases, and enforced lockdowns on a level of the communities made a breakthrough the outdated stereotypes and system constraints. It justified the effectiveness of the Telemedicine services to ensure access of the families to the essential health services.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
            <ul className="bullets">
                <li>UNICEF provided support to MOH in developing the clinical standards to manage the health status of the women, children during the COVID, procured equipment, supported capacity building, and training sessions.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Kyrgyzstan
