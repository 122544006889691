// Import Image
import Image from './../../images/bulgaria.jpg'

// Component
const Bulgaria  = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>One of the most significant issues during the COVID-19 pandemic is related to accessing the most vulnerable communities. At the beginning of the lockdown, fieldwork was extremely limited. Bulgaria CO started cooperating with the National network of health mediators to improve the conditions for the most vulnerable population in marginalized Roma communities. Thanks to the health mediators were distributed PPE and hygiene, as well as communication materials and relevant information.</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Bulgaria 
