const Moldova = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health facilities staff</li>
                <li>Non-health facilities staff</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Delivery of PPE to health facilities that were severely affected by a shortage of essential PPE.</li>
                <li>In coordination with the Ministry of Health, Labor and Social Protection (MHLSP), using its global supply networks and with the support from the UNICEF Supply Division in Copenhagen, PPE (surgical masks, N95 masks, face shields, gowns, gloves, and thermometers) were procured and delivered to all 58 health facilities, that provide services to COVID19 affected population, border police facilities, and penitentiaries.</li>
                <li>Through local procurement, additional PPE was primarily procured (but not exclusively) for non-health staff that provides services for children, women victims of violence, placement centers.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>UNICEF Moldova Office, together with the WHO office and UNICEF Office in Romania, supported the translation of the Romanian language HealthBudy chatbot that can be used by Romanian language speakers to be informed about COVID19. </li>
                <li>U report used Viber social media platform to reach more young people with information and to learn from them about the significant challenges during 2020.</li>
                <li>Upartners platform was used to respond to young people's queries related to mental health issues and reproductive health issues. Around 800 young people were reached with information based on their needs</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
            <ul className="bullets">
                <li>Support in adjusting protocols on the provision of services to pregnant women, newborns, and children in outpatient and inpatient care.</li>
                <li>Support in organizing provision of PHC to mothers and children in the context of COVID-19.</li>
                <li>Support in immunization catch-up campaign that was suspended during the two months lockdown.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Moldova
