import { useState } from 'react'
// Import Components
import TitleBox from '../../components/TitleBox'
// Page Contents
import Achievements1 from './Achievements1'
import Achievements2 from './Achievements2'
import Achievements3 from './Achievements3'
import Achievements4 from './Achievements4'

// Component
const Achievements = () => {

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
    setShowMenu3(false)
    setShowMenu4(false)
  }
  function showMenu3Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(true)
    setShowMenu4(false)
  }
  function showMenu4Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(true)
  }

  return (
    <div className="pages">
      
      <TitleBox
        bread = {'Achievements and Progress'}
        title={'Country Offices with an active health and nutrition response'}
      />
      <article className="article_body h-full relative block pt-6">
        
        {showMenu1 && <Achievements1/>}
        {showMenu2 && <Achievements2 />}
        {showMenu3 && <Achievements3/>}
        {showMenu4 && <Achievements4/>}

        <p className="pl-6 pr-10 py-2 text-sm">Click to select</p>
        <div className="w-full flex flex-row items-stretch flex-wrap">
          
          {/* MENU 1 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-main_blue hover:bg-main_blue_90 text-white
          "
            onClick={showMenu1Handler}
          >
            <div className="flex-grow">Protection and Prevention Equipment (PPE)</div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 2 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
          "
            onClick={showMenu2Handler}
          >
            <div className="flex-grow">Continuation of essential health and nutrition services including, Immunization and IYCF (infant and young child feeding) in the context of COVID-19</div>
            {showMenu2 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 3 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-l_green_hor_menu hover:bg-l_green_hor_menu_90 text-white
            "
              onClick={showMenu3Handler}
            >
            <div className="flex-grow">Innovations and use of digital technology</div>
            {showMenu3 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 4 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-d_green_hor_menu hover:bg-d_green_hor_menu_90 text-white
            "
              onClick={showMenu4Handler}
            >
            <div className="flex-grow">Health System Strengthening for COVID-19</div>
            {showMenu4 ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
            }
          </div>
          
        </div>

      </article>

    </div>
  ) 
}

export default Achievements
