const Montenegro = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Disadvantaged school students and persons in quarantine</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Support of the most vulnerable children and their families.</li>
                <li>Leading the component on Risk Communication and Community Engagement (RCCE).</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>Parenting App under development at ECARO level, adaptations are being made at CO level to enable use at the national level.</li>
                <li>Online supervision sessions organized for home visiting nurses</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
            <ul className="bullets">
                <li>RCCE (direct support to IPH, MOH, and Clinical Center of Montenegro to strengthen capacities on RCCE and conduct campaigns, collaboration with health institutions on perception surveys, and joint campaigns on social media to reach as many people as possible with accurate information and messages related to COVID-19 pandemic.</li>
                <li>The Office successfully raised funds for response to COVID and declining immunization rates, the Action "Strengthening health system resilience and response to the COVID-19 pandemic and epidemiological risks", based on a health system strengthening approach (policies, capacities, data, supplies, demand generation).</li>
                <li>Sharing and distributing regional and global guidelines, guidance notes, Q{'&'}As, and other relevant documents. </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Montenegro
