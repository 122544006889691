const Uzbekistan = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health professionals</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Procurement PPE for lab technicians, doctors, and nurses working in infectious disease hospitals through UNICEF SD.</li>
                <li>Procurement of PPE for national immunization program through local procurement.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul>
                <li>n/a</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
              <ul className="bullets">
                <li>UNICEF has procured 45 sets of web-conferencing equipment for the national and regional perinatal and pediatric centers and sanitary-epidemiological service centers. Upon delivery and installation of the equipment, all facilities will be connected to a network for online cooperation and virtual learning during and after COVID-19. </li>
                <li>UNICEF is also closely working with the Tashkent Pediatric Medical Institute to create a package of online training on contemporary issues in MCH for the in-service professional development of healthcare providers, including a course on IPC and C-19 prevention and control. The training modules include Hand Hygiene, Routine Practices, and the Chain of Transmission. The course on IPC will be a self-study course for workplace training. Each module is enhanced with photos, videos, and graphics to illustrate major concepts and practical skills such as proper hand washing procedures and donning and doffing PPE. Pre- and post-tests, case situations, and multiple-choice quizzes will help to measure the quality of skills development and knowledge transfer.</li>
                <li>Once the online training is endorsed by the national authorities, it will be loaded to the Learning Management System (MOODLE) of the Tashkent Pediatric Medical Institute. Strong emphasis will be put on developing staff's digital competencies and facilitating their education in a virtual environment. All healthcare workers across the country will be able to access this training for free.</li>
                <li>It is expected that online training on IPC will provide equitable, convenient, and effective access to standardized, up-to-date information on best practices in infection control and prevention and fill the gaps in current training resources</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
              <ul className="bullets">
                <li>Development of online platform.</li>
                <li>Conduction of self-assessment on WASH in health care facilities which results will be used to advocate the need for more attention on WASH at PHC level.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Uzbekistan
