const Albania = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Frontline public-sector workers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Provision of PPE to over 550 healthcare workers.</li>
                <li>Procurement and delivery of 2,500 COVID-19 diagnostic tests for use in maternity, neonatal and pediatric health facilities.</li>
                <li>In Abkhazia, Georgia, UNICEF provided PPEs, hygiene, and medical supplies to 1,030 healthcare workers in children's policlinics, maternity wards, COVID laboratories, and the COVID hospital. </li>
                <li>Key social service providers received support in Abkhazia. All district units of Child and Family Social Service, two local organizations providing social assistance to vulnerable children and their families, and 4 day-care centers for children with special needs, were equipped with PPE (masks and gloves) to enable the provision of social services safely.</li>
                <li>Overall, 80 staff members, as well as volunteers engaged in COVID-19 response, benefitted from assistance.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>UNICEF supported the Government in assessing infection prevention and control (IPC) in all (84) maternities. Based on the findings from the assessments, each maternity was provided with recommendations and subsequent on-job training to improve IPC, reaching approximately 580 healthcare workers. In Abkhazia, Georgia, IPC training was conducted for 174 primary healthcare and policlinic nurses and assistants.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>UNICEF supported the introduction of a new service delivery model - Shared Medical Appointments (SMA) to ensure the continuity of antenatal care services in the context of the COVID-19 pandemic. All pregnant women in Georgia (App. 26,000) have been invited for the remote antenatal consultations delivered simultaneously to the groups of 20-25 pregnant. The shared care format enabled pregnant to spend more time with their clinician and to interact with one another, both of which showed to be calming in the context of social isolation. </li>
                <li>UNICEF supported the Government in providing a centralized communications platform to all rural doctors across the country. The platform includes one main server, which hosts various cloud-based video conferencing tools for group training, has a possibility of sending SMS notifications simultaneously; collecting all interactions with doctors in a video format; giving assignments to doctors and monitoring the progress; allowing sharing of training materials in a written as well as in multimedia formats. </li>
                <li>UNICEF, in partnership with Czech Caritas continues to deliver the training via the platform to the rural doctors, but most importantly, the teleconsultations have also been adopted. The rural doctors are given the space to discuss with the specialists concrete cases from their practice related to the training topic and collect the expert's recommendations. The knowledge gained allows the rural doctors to practice teleconferencing – remotely interact with the patients, including parents/caregivers of rural children. </li>
                <li>Moreover, more than 1000 ambulatories in the country – currently with no internet connection – are getting connected to the net with UNICEF support. UNICEF is committed to cover the internet infrastructure installation cost, as well as 6 months of internet service cost. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>n/a</td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Albania
