const Bosnia  = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health and Non-health sector workers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Over 1 million masks were distributed to schools, centers for social welfare, Roma communities as well as refugees and migrants.</li>
                <li>Over 2,600 people were provided with non-contact thermometers and goggles, which were distributed to health facilities and schools.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>UNICEF engaged a communication consultant for the Public Health Institute of the Federation of Bosnia and Herzegovina (FBiH PHI) who is providing direct support in communicating the importance of immunization to communities and is direct support to Cantons for catch-up activities. Various activities were undertaken to address the issue of low immunization coverage. Root Cause Analysis of Low Immunization Coverage was developed in three Cantons in FBiH (Sarajevo, Zenica Doboj, and West Herzegovina Cantons) and two Regions in RS (Bijeljina and Banja Luka). Considering the frequent measles outbreaks the country experiences and the low coverage with the MMR vaccine, government authorities, with technical support from UNICEF, are developing Preparedness and Outbreak Response Plans for Measles. Immunization coverage in Roma communities is very low (latest estimates are around 5% with all vaccines), therefore through Roma NGOs (Kali Sara and Romalen), activities in Roma communities were conducted to increase awareness on the importance of immunization through a door-to-door campaign using trained Roma mediators, an extensive media campaign and evidence generation. In addition, UNICEF continues to support communication for social change to address the anti-vaccine sentiment and restore overall trust in immunization by maintaining an interactive web platform (blog vaccine.ba).</li>
                <li>Support of the preparation of an additional six maternity wards for Baby-Friendly Hospital accreditation in Republika Srpska. The aim is to support the Government in accrediting all maternity wards in RS to increase breastfeeding rates which are only at 19% overall in BiH. </li>
                <li>Support provision of pediatric services, including immunization, ophthalmologic, and dentistry care for refugee and migrant children currently in Temporary Reception Centres, along with supporting 5 Mother and Baby Corners for children under 5 years of age and their parents/guardians. In 2020 over 18,000 health services were provided to refugee and migrant children and their families.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
              <ul className="bullets">
                <li>UNICEF with Ministries of Health and PHIs continued promoting an innovative 'Immunization App' that provides parents/caregivers with information on when the next vaccination is due and the closest health centre to make an appointment.</li>
                <li>UNICEF and PHIs also partnered with a local NGO to operate a dedicated blog to provide parents/caregivers with evidence-based facts on vaccines in user-friendly language. Content created included the Kids TV puppet show explaining COVID-19 prevention measures, including hygiene and healthy nutrition.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
              <ul className="bullets">
                <li>An Infection, Prevention and Control (IPC) programme was set up to support health facilities and schools, using the latest available evidence and reaching over 100 professionals in 2020, and supporting government partners to procure and deploy COVID-19 vaccines in a safe, efficient and effective manner. At the request of government partners, UNICEF started conducting a comprehensive cold chain assessment for the immunization programme, including COVID-19 vaccines. UNICEF was also selected by the Council of Ministers of Bosnia and Herzegovina as procurement coordinator for COVID-19 vaccines through the COVAX mechanism.</li>
                <li>72 mobile phones and tablets were procured for mental health centres in both RS and FBiH at the request of government partners to ensure community access (electronically) to mental health services. Furthermore, UNICEF BiH supported the Second International Congress of Child and Adolescent Psychotherapy organized by the FBiH MoH. </li>
                <li>The adoption of a new multi-sectoral 2020-2025 Early Childhood Development Strategy for the Federation of Bosnia and Herzegovina ensured the commitment and continuation of improving sustainable Early Growth and Development services in the country. </li>
                <li>Support the preparation of an additional six maternity wards for Baby-Friendly Hospital accreditation in Republika Srpska. The aim is to support the Government in accrediting all maternity wards in RS to increase breastfeeding rates which are only at 19% overall in BiH. </li>
                <li>Support provision of pediatric services, including immunization, ophthalmologic, and dentistry care for refugee and migrant children currently in Temporary Reception Centres, along with supporting 5 Mother and Baby Corners for children under 5 years of age and their parents/guardians. In 2020 over 18,000 health services were provided to refugee and migrant children and their families.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Bosnia 
