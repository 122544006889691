// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/provision1.jpg'

// Component
const Provision1 = () => {

  return (
    <>
      <TitleBox
        bread = {'CHALLENGES AND THE WAY FORWARD'}
        title={'Provision of PPE'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Inaccurate PPE forecasting due to challenges in using WHO PPE forecast tool</li>
          <li>Lack of capacity and resources of small supplies causing delays in provision of supplies</li>
          <li>High global demand and shortages of PPE and other medical supplies in the global market</li>
          <li>Difficulties in transport, custom clearance, and restrictions; and distribution by the MOH</li>
          <li>Poor prioritization of PPE recipient groups, e.g. between community health workers and non-health staff</li>
          <li>Gaps in capacities of partners to distribute the allocated support and help</li>
          <li>Poor knowledge and training of health workers in PPE use</li>
          <li>Temporary challenges in access to vulnerable communities and health professionals in rural areas</li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </>
  ) 
}

export default Provision1
