const Georgia = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>UNICEF initiative on online consultations for pregnant women on COVID-19.</p>
        <p>UNICEF is partnering with the National Center for Disease Control, and Public Health launched the new initiative on online consultations for pregnant women on COVID-19 pandemic. An approximately 26,000 registered pregnant women in Georgia were registered and offered virtual consultations with gynecologists. Since June 2020, leading doctors have organized online consultations for 26,000 pregnant women.</p> 
        <p>The virtual sessions are organized to mitigate the risks associated with COVID-19. During the online consultations, each woman received individual attention from the doctor. The sessions gathered up to 25 pregnant women together via the virtual platform and allowed them to learn about the issues connected with pregnancy from both the clinician and their peers.</p>
        <p>During its initial phase, the project involved women living in the districts most affected by the Coronavirus - like Marneuli and Bolnisi municipalities - and who have had to stay under the strict quarantine restrictions.</p>
        <p>The sessions included discussions about COVID-19 and allowed time for questions and answers. Every participant was able to ask a question while staying anonymous, and all participants could hear the doctor's reply to their queries.</p>
      </div>
      <div className="bg-main_blue px-8 py-6 text-3xl text-white text-bold">
      The working experience gathered from this project will serve as a basis for the development of a telemedicine programme for low-risk pregnancies to become a new model for antenatal care delivery in Georgia.
      </div>
    </article>
  ) 
}

export default Georgia
