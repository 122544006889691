// Import Image
import Image from './../../images/ukraine.jpg'

// Component
const Ukraine = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>UNICEF Ukraine Partnered with Private Sector to Strengthen Health Care System during COVID-19</p>
        <p>The COVID-19 pandemic has drastically affected national healthcare systems globally but shows the most dramatic influence in countries with limited resources. In Ukraine, the pandemic put a strenuous burden on already existing difficulties of access and quality of medical services for COVID and non-COVID patients due to lack of staff, low diagnostic and treatment capacity, ongoing healthcare transformation and protracted conflict in the eastern areas of the country.</p>
        <p>With the increasing number of COVID patients, overloaded hospitals and increased healthcare needs, regions' capacity to ensure effective coverage with oxygen delivery and diagnostic services slowly decreased.</p>
        <p>In this challenging and rapidly developing situation, the private sector took a proactive position and showed the ability to support healthcare systems at a local, regional and national level.</p>
        <p>Taking advantage of the increased support, UNICEF Ukraine engaged in collaboration with a wide range of private partners from national and international companies to support health care providers within the UNICEF programmatic and emergency response frame.</p>
        <p>Strong collaboration between programme and operational teams with quick and intelligent decisions, an appropriate level of flexibility and supporting supervision resulted in rapid and effective actions and win-win outputs.  The UNICEF team provided comprehensive expertise, took leadership in negotiations with the government, regional and local authorities, and health providers, and provided in-depth analyses of dynamics and trends. This led to complete trust from the private sector as a partner with the capacity and do-ability to detect the most critical gaps and provide immediate support for those locations.</p>
        <p>As a result, during March-October 2020, in close cooperation with the private sector, UNICEF Ukraine supported more than 100 hospitals and primary health care facilities in almost all regions of the country, including the conflict-affected areas of eastern Ukraine.</p>
        <p>Around 300 oxygen concentrators, more than 1,000 pulse oximeters and IR thermometers, tons of sanitizers, disinfectants and blood analyzers, more than 50,000 tons of drinking water, as well as PPE kits for hospital and COVID-laboratory staff were distributed and improved the capacity of health facilities to provide diagnostic and life-saving procedures for more than 300,000 people.</p>
        <p>In addition to short-term results, the successful collaboration between UNICEF and the private sector in the fight against COVID-19 has become a platform for further mid-and long-term projects to strengthen programming in alignment with the UNICEF strategy for health, nutrition and HIV in Ukraine.</p>
        <p>https://unicef.sharepoint.com/:b:/r/teams/PD-HealthOAD/DocumentLibrary1/Global%20Health%20Newsletter/Global%20Health%20Newsletter%202021-01/Experiences%20HSS%20graduates%20COVID%2019%20Private%20sector%20providers%20UKR.pdf?csf=1&web=1&e=NOsPQJ </p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Ukraine
