// Import Image
import Image from './../../images/kazakhstan.jpg'

// Component
const Kazakhstan = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Based on the South-South cooperation modality UNICEF Kazakhstan and UNICEF Ukraine conducted 2 TOT Online training pieces, including Improve infection prevention and control/WASH in health facilities and Comprehensive home visiting during COVID-19 time and capacitated 50 local health service providers. Based on Ukrainian experience, Kazakhstan conducted IPC/WASH external assessment in selected seven regions and based on Kazakhstani experience Ukraine started piloting comprehensive home visiting in selected regions.</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Kazakhstan
