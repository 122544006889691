import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

// Component
const Chart03 = () => {

  const topValue = 2000000;
  const items = React.useMemo( () => [
    { 
      id: 1,
      country: 'Albania',
      classItem1: 'albania_1',
      classItem2: 'albania_2',
      target: 172400,
      reached: 244450,
      per: '142%',
      target_p: (172400 * 100) / topValue,
      reached_p: (244450 * 100) / topValue,
      label_per: (244450 * 100) / topValue,
    },
    { 
      id: 2,
      country: 'Armenia',
      classItem1: 'armenia_1',
      classItem2: 'armenia_2',
      target: 6000,
      reached: 7103,
      per: '118%',
      target_p: (6000 * 100) / topValue,
      reached_p: (7103 * 100) / topValue,
      label_per: (7103 * 100) / topValue,
    },
    { 
      id: 3,
      country: 'Belarus',
      classItem1: 'belarus_1',
      classItem2: 'belarus_2',
      target: 80000,
      reached: 81025,
      per: '101%',
      target_p: (80000 * 100) / topValue,
      reached_p: (81025 * 100) / topValue,
      label_per: (81025 * 100) / topValue,
    },
    { 
      id: 4,
      country: 'Bosnia and Herzegovina',
      classItem1: 'bosnia_1',
      classItem2: 'bosnia_2',
      target: 35000,
      reached: 76200,
      per: '218%',
      target_p: (35000 * 100) / topValue,
      reached_p: (76200 * 100) / topValue,
      label_per: (76200 * 100) / topValue,
    },
    { 
      id: 5,
      country: 'Bulgaria',
      classItem1: 'bulgaria_1',
      classItem2: 'bulgaria_2',
      target: 20000,
      reached: 21559,
      per: '108%',
      target_p: (20000 * 100) / topValue,
      reached_p: (21559 * 100) / topValue,
      label_per: (21559 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Croatia',
      classItem1: 'croatia_1',
      classItem2: 'croatia_2',
      target: 2000,
      reached: 1818,
      per: '91%',
      target_p: (2000 * 100) / topValue,
      reached_p: (1818 * 100) / topValue,
      label_per: (2000 * 100) / topValue,
    },
    { 
      id: 7,
      country: 'Georgia',
      classItem1: 'georgia_1',
      classItem2: 'georgia_2',
      target: 220000,
      reached: 218434,
      per: '99%',
      target_p: (220000 * 100) / topValue,
      reached_p: (218434 * 100) / topValue,
      label_per: (218434 * 100) / topValue,
    },
    { 
      id: 8,
      country: 'Greece Partnership Office',
      classItem1: 'greece_1',
      classItem2: 'greece_2',
      target: 36000,
      reached: 20312,
      per: '56%',
      target_p: (36000 * 100) / topValue,
      reached_p: (20312 * 100) / topValue,
      label_per: (36000 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'Kosovo',
      classItem1: 'kosovo_1',
      classItem2: 'kosovo_2',
      target: 120900,
      reached: 120900,
      per: '100%',
      target_p: (120900 * 100) / topValue,
      reached_p: (120900 * 100) / topValue,
      label_per: (120900 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'Macedonia',
      classItem1: 'macedonia_1',
      classItem2: 'macedonia_2',
      target: 10000,
      reached: 16310,
      per: '163%',
      target_p: (10000 * 100) / topValue,
      reached_p: (16310 * 100) / topValue,
      label_per: (16310 * 100) / topValue,
    },
    { 
      id: 11,
      country: 'Rep. of Turkmenistan',
      classItem1: 'turkmenistan_1',
      classItem2: 'turkmenistan_2',
      target: 9000,
      reached: 9100,
      per: '101%',
      target_p: (9000 * 100) / topValue,
      reached_p: (9100 * 100) / topValue,
      label_per: (9100 * 100) / topValue,
    },
    { 
      id: 12,
      country: 'Republic of Kyrgyzstan',
      classItem1: 'kyrgyzstan_1',
      classItem2: 'kyrgyzstan_2',
      target: 1890000,
      reached: 1960840,
      per: '104%',
      target_p: (1890000 * 100) / topValue,
      reached_p: (1960840 * 100) / topValue,
      label_per: (1960840 * 100) / topValue,
    },
    { 
      id: 13,
      country: 'Republic of Montenegro',
      classItem1: 'montenegro_1',
      classItem2: 'montenegro_2',
      target: 16000,
      reached: 24000,
      per: '150%',
      target_p: (16000 * 100) / topValue,
      reached_p: (24000 * 100) / topValue,
      label_per: (24000 * 100) / topValue,
    },
    { 
      id: 14,
      country: 'Serbia',
      classItem1: 'serbia_1',
      classItem2: 'serbia_2',
      target: 50000,
      reached: 53271,
      per: '107%',
      target_p: (50000 * 100) / topValue,
      reached_p: (53271 * 100) / topValue,
      label_per: (53271 * 100) / topValue,
    },
    { 
      id: 15,
      country: 'Tajikistan',
      classItem1: 'tajikistan_1',
      classItem2: 'tajikistan_2',
      target: 20000,
      reached: 58421,
      per: '292%',
      target_p: (20000 * 100) / topValue,
      reached_p: (58421 * 100) / topValue,
      label_per: (58421 * 100) / topValue,
    },
    { 
      id: 16,
      country: 'Turkey',
      classItem1: 'turkey_1',
      classItem2: 'turkey_2',
      target: 450000,
      reached: 524527,
      per: '117%',
      target_p: (450000 * 100) / topValue,
      reached_p: (524527 * 100) / topValue,
      label_per: (524527 * 100) / topValue,
    },
    { 
      id: 17,
      country: 'Ukraine',
      classItem1: 'ukraine_1',
      classItem2: 'ukraine_2',
      target: 400000,
      reached: 706462,
      per: '177%',
      target_p: (400000 * 100) / topValue,
      reached_p: (706462 * 100) / topValue,
      label_per: (706462 * 100) / topValue,
    },
  ])

  
  const [showTarget, setShowTarget] = useState(true);
  const showTargetHandler = () => setShowTarget(!showTarget);
  const [showReached, setShowReached] = useState(true);
  const showReachedHandler = () => setShowReached(!showReached);

  return (
    <>
      <div className="article_content pl-6 pr-10 mt-16">
        <h1 className="text-xl font-bold text-main_blue">Number of people reached with critical WASH supplies (including hygiene items) and services</h1>
        <p className="py-2 text-sm">* Achieved %</p>
        <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars relative">
                  
                  {showTarget && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#00a0e9",
                          width: `${row.target_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem1}
                      ></a>
                      <ReactTooltip id={row.classItem1} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.target}</p>
                      </ReactTooltip>
                    </>
                  }

                  {showReached && 
                    <>
                      <a
                        className="bar"
                        style={{
                          backgroundColor: "#39419a",
                          width: `${row.reached_p}%`,
                          height: "12px",
                        }}
                        data-tip data-for={row.classItem2}
                      ></a>
                      <ReactTooltip id={row.classItem2} className='barTooltipe' aria-haspopup='true'>
                        <p className="tollValue">{row.reached}</p>
                      </ReactTooltip>
                    </>
                  }

                  <div className="absolute font-bold text-sm" style={{ left:`${row.label_per + 2}%`, top: "6px" }}>{ row.per }*</div>

                </div>
              </div>
            )
          })}

        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>500000</span></div>
            <div><span>1000000</span></div>
            <div className="sm:hidden"><span>1500000</span></div>
            <div><span>2000000</span></div>
          </div>
        </div>
      </div>
      </div>

      {/* SORT CHECKBOXES */}
      <p className="pl-6 pr-10 py-2 text-sm">Click to select/deselect</p>
      <div className="w-full flex flex-row items-stretch flex-wrap">

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-main_blue hover:bg-main_blue_90 text-white
        "
          onClick={showTargetHandler}
        >
          <div className="flex-grow">Sum of Target</div>
          {showTarget ? 
            <span className="icon-circle-filled px-3 text-2xl"></span> :
            <span className="icon-circle-outline px-3 text-2xl"></span>
          }
        </div>

        <div className="
          flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
          pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
          bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
        "
          onClick={showReachedHandler}
        >
          <div className="flex-grow">Number Reached</div>
          {showReached ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
          }
        </div>
      
      </div>
      
    </>
  )
}

export default Chart03 
