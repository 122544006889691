const Home = () => {
  return (
    <article className="article_home px-4 pt-4 relative">
      <div className="absolute left-10 bottom-10 text-left">
        <h1 className="text-6xl lg:text-5xl md:text-4xl xs:text-2xl xxs:text-xl text-main_blue">Our mandate:</h1>
        <h1 className="text-6xl lg:text-5xl md:text-4xl xs:text-2xl xxs:text-xl text-white">no child left</h1>
        <h1 className="text-6xl lg:text-5xl md:text-4xl xs:text-2xl xxs:text-xl text-white">behind</h1>
      </div>
    </article>
  )
}
// Export
export default Home
