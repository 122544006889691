import { useState } from 'react'
// Import Components
import TitleBox from '../../components/TitleBox'
import BtnFlagName from './BtnFlagName'
import BtnFlag from './BtnFlag'
// Page Content
import Albania from './Albania'
import Azerbaijan from './Azerbaijan'
import Armenia  from './Armenia'
import Belarus  from './Belarus'
import Bosnia  from './Bosnia'
import Bulgaria  from './Bulgaria'
import Croatia from './Croatia'
import Georgia from './Georgia'
import Kazakhstan from './Kazakhstan'
import Kyrgyzstan from './Kyrgyzstan'
import Kosovo from './Kosovo'
import Macedonia from './Macedonia'
import Moldova from './Moldova'
import Montenegro from './Montenegro'
import Serbia from './Serbia'
import Romania from './Romania'
import Turkmenistan from './Turkmenistan'
import Turkey from './Turkey'
import Ukraine from './Ukraine'
import Uzbekistan from './Uzbekistan'
// Flags
import FlagAlbania from './../../images/flag_albania.svg'
import FlagAzerbaijan from './../../images/flag_azerbaijan.svg'
import FlagArmenia from './../../images/flag_armenia.svg'
import FlagBelarus from './../../images/flag_belarus.svg'
import FlagBosnia from './../../images/flag_bosnia.svg'
import FlagBulgaria from './../../images/flag_bulgaria.svg'
import FlagCroatia from './../../images/flag_croatia.svg'
import FlagGeorgia from './../../images/flag_georgia.svg'
import FlagKazakhstan from './../../images/flag_kazakhstan.svg'
import FlagKosovo from './../../images/flag_kosovo.svg'
import FlagKyrgyzstan from './../../images/flag_kyrgyzstan.svg'
import FlagMacedonia from './../../images/flag_macedonia.svg'
import FlagMoldova from './../../images/flag_moldova.svg'
import FlagMontenegro from './../../images/flag_montenegro.svg'
import FlagRomania from './../../images/flag_romania.svg'
import FlagTurkmenistan from './../../images/flag_turkmenistan.svg'
import FlagTurkey from './../../images/flag_turkey.svg'
import FlagSerbia from './../../images/flag_serbia.svg'
import FlagUkraine from './../../images/flag_ukraine.svg'
import FlagUzbekistan from './../../images/flag_uzbekistan.svg'

// Component
const Success = () => {

  const [showCountryFlags, setShowCountryFlags] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [showAlbania, setShowAlbania] = useState(false);
  const [showAzerbaijan, setShowAzerbaijan] = useState(false);
  const [showArmenia, setShowArmenia] = useState(false);
  const [showBelarus, setShowBelarus] = useState(false);
  const [showBosnia, setShowBosnia] = useState(false);
  const [showBulgaria, setShowBulgaria] = useState(false);
  const [showCroatia, setShowCroatia] = useState(false);
  const [showGeorgia, setShowGeorgia] = useState(false);
  const [showKazakhstan, setShowKazakhstan] = useState(false);
  const [showKosovo, setShowKosovo] = useState(false);
  const [showKyrgyzstan, setShowKyrgyzstan] = useState(false);
  const [showMoldova, setShowMoldova] = useState(false);
  const [showMontenegro, setShowMontenegro] = useState(false);
  const [showMacedonia, setShowMacedonia] = useState(false);
  const [showRomania, setShowRomania] = useState(false);
  const [showSerbia, setShowSerbia] = useState(false);
  const [showTurkmenistan, setShowTurkmenistan] = useState(false);
  const [showTurkey, setShowTurkey] = useState(false);
  const [showUkraine, setShowUkraine] = useState(false);
  const [showUzbekistan, setShowUzbekistan] = useState(false);
  
  function showAlbaniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(true)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showKosovoHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(true)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showAzerbaijanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(true)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showArmeniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(true)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showBelarusHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(true)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showBosniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(true)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showBulgariaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(true)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showCroatiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(true)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showGeorgiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(true)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showUkraineHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(true)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showKazakhstanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(true)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showKyrgyzstanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(true)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showMacedoniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(true)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showMoldovaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(true)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showMontenegroHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(true)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showRomaniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(true)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showTurkmenistanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(true)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showTurkeyHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(true)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showSerbiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(true)
    setShowUzbekistan(false)
    setShowUkraine(false)
  }
  function showUzbekistanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(true)
    setShowUkraine(false)
  }
  function showUkraineHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryFlags(true)
    setShowAlbania(false)
    setShowKosovo(false)
    setShowAzerbaijan(false)
    setShowArmenia(false)
    setShowBosnia(false)
setShowBelarus(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowUkraine(false)
    
    setShowKazakhstan(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowTurkmenistan(false)
    setShowTurkey(false)
    setShowSerbia(false)
    setShowUzbekistan(false)
    setShowUkraine(true)
  }

  const breadTxt = 'SUCCESS STORIES'

  return (
    <div className="pages">
      
      {showMain && <TitleBox bread = {breadTxt} title={'Country offices success stories'} />}
      {showAlbania && <TitleBox bread = {breadTxt} title={'Albania'} />}
      {showAzerbaijan && <TitleBox bread = {breadTxt} title={'Azerbaijan'} />}
      {showArmenia && <TitleBox bread = {breadTxt} title={'Armenia'} />}
      {showBosnia && <TitleBox bread = {breadTxt} title={'Bosnia & Herzegovina'} />}
      {showBulgaria && <TitleBox bread = {breadTxt} title={'Bulgaria'} />}
      {showCroatia && <TitleBox bread = {breadTxt} title={'Croatia'} />}
      {showGeorgia && <TitleBox bread = {breadTxt} title={'Georgia'} />}
      {showKazakhstan && <TitleBox bread = {breadTxt} title={'Kazakhstan'} />}
      {showKosovo && <TitleBox bread = {breadTxt} title={'Kosovo'} />}
      {showKyrgyzstan && <TitleBox bread = {breadTxt} title={'Kyrgyzstan'} />}
      {showMoldova && <TitleBox bread = {breadTxt} title={'Moldova'} />}
      {showMontenegro && <TitleBox bread = {breadTxt} title={'Montenegro'} />}
      {showMacedonia && <TitleBox bread = {breadTxt} title={'North Macedonia'} />}
      {showRomania && <TitleBox bread = {breadTxt} title={'Romania'} />}
      {showSerbia && <TitleBox bread = {breadTxt} title={'Serbia'} />}
      {showTurkey && <TitleBox bread = {breadTxt} title={'Turkey'} />}
      {showTurkmenistan && <TitleBox bread = {breadTxt} title={'Turkmenistan'} />}
      {showUkraine && <TitleBox bread = {breadTxt} title={'Ukraine'} />}
      {showUzbekistan && <TitleBox bread = {breadTxt} title={'Uzbekistan'} />}
       
      {showMain && 
        <article className="article_body px-4 pt-4">
          <div className="
            flex flex-row flex-wrap content-around justify-items-center
          ">
            <BtnFlagName
              title = {'Albania'}
              flag = {FlagAlbania}
              handler = {showAlbaniaHandler}
            />
            <BtnFlagName
              title = {'Azerbaijan'}
              flag = {FlagAzerbaijan}
              handler = {showAzerbaijanHandler}
            />
            <BtnFlagName
              title = {'Armenia'}
              flag = {FlagArmenia}
              handler = {showArmeniaHandler}
            />
            {/* <BtnFlagName
              title = {'Belarus'}
              flag = {FlagBelarus}
              handler = {showBelarusHandler}
            /> */}
            <BtnFlagName
              title = {'Bosnia & \nHerzegovina'}
              flag = {FlagBosnia}
              handler = {showBosniaHandler}
            />
            <BtnFlagName
              title = {'Bulgaria'}
              flag = {FlagBulgaria}
              handler = {showBulgariaHandler}
            />
            <BtnFlagName
              title = {'Croatia'}
              flag = {FlagCroatia}
              handler = {showCroatiaHandler}
            />
            <BtnFlagName
              title = {'Georgia'}
              flag = {FlagGeorgia}
              handler = {showGeorgiaHandler}
            />
            <BtnFlagName
              title = {'Kazakhstan'}
              flag = {FlagKazakhstan}
              handler = {showKazakhstanHandler}
            />
            {/* <BtnFlagName
              title = {'Kosovo'}
              flag = {FlagKosovo}
              handler = {showKosovoHandler}
            /> */}
            {/* <BtnFlagName
              title = {'Kyrgyzstan'}
              flag = {FlagKyrgyzstan}
              handler = {showKyrgyzstanHandler}
            /> */}
            <BtnFlagName
              title = {'Moldova'}
              flag = {FlagMoldova}
              handler = {showMoldovaHandler}
            />
            <BtnFlagName
              title = {'Montenegro'}
              flag = {FlagMontenegro}
              handler = {showMontenegroHandler}
            />
            <BtnFlagName
              title = {'North Macedonia'}
              flag = {FlagMacedonia}
              handler = {showMacedoniaHandler}
            />
            <BtnFlagName
              title = {'Romania'}
              flag = {FlagRomania}
              handler = {showRomaniaHandler}
            />
            <BtnFlagName
              title = {'Serbia'}
              flag = {FlagSerbia}
              handler = {showSerbiaHandler}
            />
            <BtnFlagName
              title = {'Turkey'}
              flag = {FlagTurkey}
              handler = {showTurkeyHandler}
            />
            <BtnFlagName
              title = {'Turkmenistan'}
              flag = {FlagTurkmenistan}
              handler = {showTurkmenistanHandler}
            />
            <BtnFlagName
              title = {'Ukraine'}
              flag = {FlagUkraine}
              handler = {showUkraineHandler}
            />
            <BtnFlagName
              title = {'Uzbekistan'}
              flag = {FlagUzbekistan}
              handler = {showUzbekistanHandler}
            />

          </div>
        </article>
      }

      {showAlbania && <Albania />}
      {showAzerbaijan && <Azerbaijan />}
      {showArmenia && <Armenia />}
      {/* {showBelarus && <Belarus />} */}
      {showBosnia && <Bosnia />}
      {showBulgaria && <Bulgaria  />}
      {showCroatia && <Croatia />}
      {showGeorgia && <Georgia />}
      {showKazakhstan && <Kazakhstan />}
      {/* {showKosovo && <Kosovo />} */}
      {/* {showKyrgyzstan && <Kyrgyzstan />} */}
      {showMoldova && <Moldova />}
      {showMontenegro && <Montenegro />}
      {showMacedonia && <Macedonia />}
      {showRomania && <Romania />}
      {showSerbia && <Serbia />}
      {showTurkey && <Turkey />}
      {showTurkmenistan && <Turkmenistan />}
      {showUkraine && <Ukraine />}
      {showUzbekistan && <Uzbekistan />}

      {showCountryFlags ?
        <>
          <p className="pl-6 pr-10 text-xs pt-6 pb-1">Click on the flags to see the countries success stories</p>
          <div className="mx-6 mb-16 relative">
            <div className="flex flex-row flex-wrap">
              {!showAlbania && <BtnFlag flag = {FlagAlbania} handler = {showAlbaniaHandler} />}
              {!showAzerbaijan && <BtnFlag flag = {FlagAzerbaijan} handler = {showAzerbaijanHandler} />}
              {!showArmenia && <BtnFlag flag = {FlagArmenia} handler = {showArmeniaHandler} />}
              {!showBosnia && <BtnFlag flag = {FlagBosnia} handler = {showBosniaHandler} />}
              {!showBulgaria && <BtnFlag flag = {FlagBulgaria} handler = {showBulgariaHandler} />}
              {!showCroatia && <BtnFlag flag = {FlagCroatia} handler = {showCroatiaHandler} />}
              {!showGeorgia && <BtnFlag flag = {FlagGeorgia} handler = {showGeorgiaHandler} />}
              {!showKazakhstan && <BtnFlag flag = {FlagKazakhstan} handler = {showKazakhstanHandler} />}
              {/* {!showKosovo && <BtnFlag flag = {FlagKosovo} handler = {showKosovoHandler} />} */}
              {/* {!showKyrgyzstan && <BtnFlag flag = {FlagKyrgyzstan} handler = {showKyrgyzstanHandler} />} */}
              {!showMoldova && <BtnFlag flag = {FlagMoldova} handler = {showMoldovaHandler} />}
              {!showMontenegro && <BtnFlag flag = {FlagMontenegro} handler = {showMontenegroHandler} />}
              {!showMacedonia && <BtnFlag flag = {FlagMacedonia} handler = {showMacedoniaHandler} />}
              {!showRomania && <BtnFlag flag = {FlagRomania} handler = {showRomaniaHandler} />}
              {!showSerbia && <BtnFlag flag = {FlagSerbia} handler = {showSerbiaHandler} />}
              {!showTurkey && <BtnFlag flag = {FlagTurkey} handler = {showTurkeyHandler} />}
              {!showTurkmenistan && <BtnFlag flag = {FlagTurkmenistan} handler = {showTurkmenistanHandler} />}
              {!showUkraine && <BtnFlag flag = {FlagUkraine} handler = {showUkraineHandler} />}
              {!showUzbekistan && <BtnFlag flag = {FlagUzbekistan} handler = {showUzbekistanHandler} />}
            </div>    
          </div>
        </> :
        <div className="mb-16"></div>
      }

    </div>
  ) 
}

export default Success
