const Table03_01 = () => {
  return (    
    <table className="allBorders w-full border xs:text-xs text-left mt-16">
      <thead className="text-white bg-main_blue">
        <tr>
            <th className="text-xl">Country Office</th>
            <th className="text-xl">Innovation in the Provision of PPE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Uzbekistan	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Croatia	</td><td>CO cooperated closely not only with the MoH, but also with the Directorate for Civil Protection in order to get smooth transition of the UNICEF tracks with the PPE from the border with Croatia and to avoid prolonged custom procedures that might have happened otherwise, which would delay delivery of much-needed PPE. Also, UNICEF tracks were escorted for the border to the Civil Protection storage. Therefore traffic jams were also avoided.</td>
        </tr>
        <tr>
          <td>Albania	</td><td>PPE planning and coordination of supply gaps from the National Health Authorities. </td>
        </tr>
        <tr>
          <td>Kyrgyzstan	</td><td>n/a</td>
        </tr>
        <tr>
          <td>North Macedonia	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Montenegro	</td><td>UNICEF CO, in close cooperation with UNDP CO, prepared a Rapid Social Impact Assessment based on quantitative and qualitative data collection to identify the children and families who are most in need to receive PPE and other necessary support. </td>
        </tr>
        <tr>
          <td>Tajikistan	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Armenia	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Moldova	</td><td>Strong cooperation with Government that organized transportation from Shanghai/China of PPE procured by UN (UNICEF, UNDP), private sector and donation) based on coordinated UN support and negotiation with Government, strong coordination with MHLSP and WHO, and planning procurement based on the identified needs. Close coordination with other UN agencies and identification of a list of local producers and vendors to allow rapid procurement of PPEs and avoid competition with the GoM (in collaboration with WHO, UNW, and UNDP).</td>
        </tr>
        <tr>
          <td>Ukraine	</td><td>Private partnership engagement to provide PPE.</td>
        </tr>
        <tr>
          <td>Turkmenistan	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Bosnia and Herzegovina	</td><td>The gathering event was used for the distribution of PPE to all Roma communities.</td>
        </tr>
        <tr>
          <td>Serbia	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Belarus	</td><td>One entry point for health care system PPE supply: All PPEs were supplied directly to the Republican Center of the Emergency Medical Response and distributed based on the request received from the medical facilities. </td>
        </tr>
        <tr>
          <td>Turkey	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kosovo	</td><td>UNICEF conducted systematic pre-delivery inspections and field monitoring of supply distribution and ensured that all supplies reached the targeted population. The inspection and monitoring visits were conducted based on a randomly selected sample from the distribution lists of personal protection equipment (PPE). The real-time information collected from the field provided critical insights and informed the design of the emergency response. </td>
        </tr>
        <tr>
          <td>Georgia	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Azerbaijan	</td><td>n/a</td>
        </tr>
        <tr>
          <td>Bulgaria CO	</td><td>Establishing partnerships with businesses to secure more PPE for professionals and vulnerable populations.</td>
        </tr>
        <tr>
          <td>Romania	</td><td>Requesting the donors to support the acquisition of PPEs for community health workers.  Botnar Foundation allocated 400,000$ for UNICEF Romania for PPEs and PCR Tests. </td>
        </tr>
        <tr>
          <td>Kazakhstan	</td><td>n/a</td>
        </tr>
      </tbody>
    
    </table>
  ) 
}
export default Table03_01