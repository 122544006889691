import { useState } from 'react'
// Import Components
import TitleBox from '../../components/TitleBox'
import Background from './../../images/bg_elements.jpg'

// Pages Content
import Albania from './Albania'
import Armenia from './Armenia'
import Azerbaijan from './Azerbaijan'
import Belarus from './Belarus'
import Bosnia from './Bosnia'
import Bulgaria from './Bulgaria'
import Croatia from './Croatia'
import Georgia from './Georgia'
import Kazakhstan from './Kazakhstan'
import Kosovo from './Kosovo'
import Kyrgyzstan from './Kyrgyzstan'
import Macedonia from './Macedonia'
import Moldova from './Moldova'
import Montenegro from './Montenegro'
import Romania from './Romania'
import Serbia from './Serbia'
import Tajikistan from './Tajikistan'
import Turkey from './Turkey'
import Turkmenistan from './Turkmenistan'
import Ukraine from './Ukraine'
import Uzbekistan from './Uzbekistan'

// Component
const Elements = () => {

  const [showCountryMenu, setShowCountryMenu] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [showAlbania, setShowAlbania] = useState(false);
  const [showArmenia, setShowArmenia] = useState(false);
  const [showAzerbaijan, setShowAzerbaijan] = useState(false);
  const [showBelarus, setShowBelarus] = useState(false);
  const [showBosnia, setShowBosnia] = useState(false);
  const [showBulgaria, setShowBulgaria] = useState(false);
  const [showCroatia, setShowCroatia] = useState(false);
  const [showGeorgia, setShowGeorgia] = useState(false);
  const [showKazakhstan, setShowKazakhstan] = useState(false);
  const [showKosovo, setShowKosovo] = useState(false);
  const [showKyrgyzstan, setShowKyrgyzstan] = useState(false);
  const [showMacedonia, setShowMacedonia] = useState(false);
  const [showMoldova, setShowMoldova] = useState(false);
  const [showMontenegro, setShowMontenegro] = useState(false);
  const [showRomania, setShowRomania] = useState(false);
  const [showSerbia, setShowSerbia] = useState(false);
  const [showTajikistan, setShowTajikistan] = useState(false);
  const [showTurkey, setShowTurkey] = useState(false);
  const [showTurkmenistan, setShowTurkmenistan] = useState(false);
  const [showUkraine, setShowUkraine] = useState(false);
  const [showUzbekistan, setShowUzbekistan] = useState(false);
  
  function showAlbaniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(true)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showArmeniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(true)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showAzerbaijanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(true)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showBelarusHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(true)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showBosniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(true)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showBulgariaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(true)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showCroatiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(true)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showGeorgiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(true)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showKazakhstanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(true)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showKosovoHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKazakhstan(false)
    setShowKosovo(true)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showKyrgyzstanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(true)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showMacedoniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(true)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showMoldovaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(true)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showMontenegroHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(true)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showRomaniaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(true)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showSerbiaHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(true)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showTajikistanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(true)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showTurkeyHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(true)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showTurkmenistanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(true)
    setShowUkraine(false)
    setShowUzbekistan(false)
  }
  function showUkraineHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(true)
    setShowUzbekistan(false)
  }
  function showUzbekistanHandler(e) {
    e.preventDefault();
    setShowMain(false)
    setShowCountryMenu(false)
    setShowAlbania(false)
    setShowArmenia(false)
    setShowAzerbaijan(false)
    setShowBelarus(false)
    setShowBosnia(false)
    setShowBulgaria(false)
    setShowCroatia(false)
    setShowGeorgia(false)
    setShowKazakhstan(false)
    setShowKosovo(false)
    setShowKyrgyzstan(false)
    setShowMacedonia(false)
    setShowMoldova(false)
    setShowMontenegro(false)
    setShowRomania(false)
    setShowSerbia(false)
    setShowTajikistan(false)
    setShowTurkey(false)
    setShowTurkmenistan(false)
    setShowUkraine(false)
    setShowUzbekistan(true)
  }

  const breadTxt = 'ELEMENTS OF UNICEF HEALTH AND NUTRITION RESPONSE TO COVID-19'

  return (
    <div className="pages">

      {showMain && <TitleBox bread = {breadTxt} title = {'Main components of UNICEF Health and Nutrition response'}/>}
      {showAlbania && <TitleBox bread = {breadTxt} title = {'Albania'}/>}
      {showArmenia && <TitleBox bread = {breadTxt} title = {'Armenia'}/>}
      {showAzerbaijan && <TitleBox bread = {breadTxt} title = {'Azerbaijan'}/>}
      {showBelarus && <TitleBox bread = {breadTxt} title = {'Belarus'}/>}
      {showBosnia && <TitleBox bread = {breadTxt} title = {'Bosnia & Herzegovina'}/>}
      {showBulgaria && <TitleBox bread = {breadTxt} title = {'Bulgaria'}/>}
      {showCroatia && <TitleBox bread = {breadTxt} title = {'Croatia'}/>}
      {showGeorgia && <TitleBox bread = {breadTxt} title = {'Georgia'}/>}
      {showKazakhstan && <TitleBox bread = {breadTxt} title = {'Kazakhstan'}/>}
      {showKosovo && <TitleBox bread = {breadTxt} title = {'Kosovo'}/>}
      {showKyrgyzstan && <TitleBox bread = {breadTxt} title = {'Kyrgyzstan'}/>}
      {showMacedonia && <TitleBox bread = {breadTxt} title = {'North Macedonia'}/>}
      {showMoldova && <TitleBox bread = {breadTxt} title = {'Moldova'}/>}
      {showMontenegro && <TitleBox bread = {breadTxt} title = {'Montenegro'}/>}
      {showRomania && <TitleBox bread = {breadTxt} title = {'Romania'}/>}
      {showSerbia && <TitleBox bread = {breadTxt} title = {'Serbia'}/>}
      {showTajikistan && <TitleBox bread = {breadTxt} title = {'Tajikistan'}/>}
      {showTurkey && <TitleBox bread = {breadTxt} title = {'Turkey'}/>}
      {showTurkmenistan && <TitleBox bread = {breadTxt} title = {'Turkmenistan'}/>}
      {showUkraine && <TitleBox bread = {breadTxt} title = {'Ukraine'}/>}
      {showUzbekistan && <TitleBox bread = {breadTxt} title = {'Uzbekistan'}/>}
      
      <article 
        className="article_body"
      >
        <div 
          className="
            w-full h-full relative
            bg-no-repeat bg-cover bg-center relative"
          style={{backgroundImage: 'url(' + Background + ')'}}
        >
          
          <div className="
            w-72 h-12 absolute m-6 p-2 pl-3 block
            bg-main_blue text-white hover:text-sec_blue
            text-xl cursor-pointer ani03s font-bold z-20
          "
            onClick={e => setShowCountryMenu(!showCountryMenu)}
          >
            {showMain && <>Select country</>}
            {showAlbania && <>Albania</>}
            {showArmenia && <>Armenia</>}
            {showAzerbaijan && <>Azerbaijan</>}
            {showBelarus && <>Belarus</>}
            {showBosnia && <>Bosnia {'&'} Herzegovina</>}
            {showBulgaria && <>Bulgaria</>}
            {showCroatia && <>Croatia</>}
            {showGeorgia && <>Georgia</>}
            {showKazakhstan && <>Kazakhstan</>}
            {showKosovo && <>Kosovo</>}
            {showKyrgyzstan && <>Kyrgyzstan</>}
            {showMacedonia && <>North Macedonia</>}
            {showMoldova && <>Moldova</>}
            {showMontenegro && <>Montenegro</>}
            {showRomania && <>Romania</>}
            {showSerbia && <>Serbia</>}
            {showTajikistan && <>Tajikistan</>}
            {showTurkey && <>Turkey</>}
            {showTurkmenistan && <>Turkmenistan</>}
            {showUkraine && <>Ukraine</>}
            {showUzbekistan && <>Uzbekistan</>}

            { showCountryMenu ?
              <span className="icon-close absolute top-3 right-2"></span> :
              <span className="icon-down absolute top-3 right-2"></span>
            }
          </div>
          { showCountryMenu &&
            <div className="flex flex-col bg-white border border-main_blue m-6 p-4 w-72 z-10 absolute">
              <ul className="pt-10">

                {!showAlbania && 
                  <li 
                    className="hover:text-main_blue cursor-pointer ani03s"
                    onClick={showAlbaniaHandler}
                    >
                    Albania
                  </li>
                }
                {!showArmenia &&
                  <li
                    className="hover:text-main_blue cursor-pointer ani03s"
                    onClick={showArmeniaHandler}
                    >
                    Armenia
                  </li>
                }
                {!showAzerbaijan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showAzerbaijanHandler}
                  >
                  Azerbaijan
                  </li>
                }
                {!showBelarus &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showBelarusHandler}
                  >
                  Belarus
                  </li>
                }
                {!showBosnia &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showBosniaHandler}
                  >
                  Bosnia {'&'} Herzegovina
                  </li>
                }
                {!showBulgaria &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showBulgariaHandler}
                  >
                  Bulgaria
                  </li>
                }
                {!showCroatia &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showCroatiaHandler}
                  >
                  Croatia
                  </li>
                }
                {!showGeorgia &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showGeorgiaHandler}
                  >
                  Georgia
                  </li>
                }
                {!showKazakhstan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showKazakhstanHandler}
                  >
                  Kazakhstan
                  </li>
                }
                {!showKosovo &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showKosovoHandler}
                  >
                  Kosovo
                  </li>
                }
                {!showKyrgyzstan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showKyrgyzstanHandler}
                  >
                  Kyrgyzstan
                  </li>
                }
                {!showMacedonia &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showMacedoniaHandler}
                  >
                  North Macedonia
                  </li>
                }
                {!showMoldova &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showMoldovaHandler}
                  >
                  Moldova
                  </li>
                }
                {!showMontenegro &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showMontenegroHandler}
                  >
                  Montenegro
                  </li>
                }
                {!showRomania &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showRomaniaHandler}
                  >
                  Romania
                  </li>
                }
                {!showSerbia &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showSerbiaHandler}
                  >
                  Serbia
                  </li>
                }
                {!showTajikistan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showTajikistanHandler}
                  >
                  Tajikistan
                  </li>
                }
                {!showTurkey &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showTurkeyHandler}
                  >
                  Turkey
                  </li>
                }
                {!showTurkmenistan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showTurkmenistanHandler}
                  >
                  Turkmenistan
                  </li>
                }
                {!showUkraine &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showUkraineHandler}
                  >
                  Ukraine
                  </li>
                }
                {!showUzbekistan &&
                  <li
                  className="hover:text-main_blue cursor-pointer ani03s"
                  onClick={showUzbekistanHandler}
                  >
                  Uzbekistan
                  </li>
                }
              </ul>
            </div>
          }

          {showMain && <div className="w-full h-full relative min-h-560"></div>}
          {showAlbania && <Albania/>}
          {showArmenia && <Armenia/>}
          {showAzerbaijan && <Azerbaijan/>}
          {showBelarus && <Belarus/>}
          {showBosnia && <Bosnia/>}
          {showBulgaria && <Bulgaria/>}
          {showCroatia && <Croatia/>}
          {showGeorgia && <Georgia/>}
          {showKazakhstan && <Kazakhstan/>}
          {showKosovo && <Kosovo/>}
          {showKyrgyzstan && <Kyrgyzstan/>}
          {showMacedonia && <Macedonia/>}
          {showMoldova && <Moldova/>}
          {showMontenegro && <Montenegro/>}
          {showRomania && <Romania/>}
          {showSerbia && <Serbia/>}
          {showTajikistan && <Tajikistan/>}
          {showTurkey && <Turkey/>}
          {showTurkmenistan && <Turkmenistan/>}
          {showUkraine && <Ukraine/>}
          {showUzbekistan && <Uzbekistan/>}

        </div>
      </article>
    </div>
  ) 
}

export default Elements
