// Import Image
import Image from './../../images/innovation.jpg'

// Component
const Armenia = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Despite the lockdown, the Office managed to organize series of activities for health providers, supported MOH in re-organizing immunization system to avoid potential risk for children and parents in getting an infection and continue immunization; provided essential support in terms of equipment and supply</p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Armenia
