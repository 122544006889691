const Ukraine = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health professionals</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>COVID-19 designated hospitals and ambulances in GCA (90%) +NGCA (10%) were equipped with PPE kits, including essential FFP2/3, masks, sanitizers, pulse oximeters, and were trained on proper self-care and safe behavior. </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>Support to oblast authorities in conflict-affected areas (GCA) with the development of ECD integrated packages of services for families with children 0-3 through universal-progressive home visiting model.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Development of a mobile application/online platform with essential medical information for families, health workers on main ECD chapters, COVID. This platform will be piloted in several regions with possible extensions to the national level.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Strengthening of the mother and child health care system at primary health care level with integrated packages of services, universal-progressive home visiting model, and promotion of safe health care in the conflict-affected areas (government-controlled) of Eastern Ukraine. This project aims to ensure uninterrupted and safe primary health care services for most vulnerable children with integrated packages of services (early childhood development, ECD) focusing mainly on pregnancy, 0-3 years old children, and their caregivers in safe health care settings with effective telemedicine and home-visiting modalities in the conflict-affected areas.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Ukraine
