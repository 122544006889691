// Import Image
import Image from './../../images/macedonia.jpg'

// Component
const Macedonia = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>COVID-19 associated funding was seen as an opportunity to upgrade the home visiting system for recording and reporting, replacing the existing paper-based forms that caused delays in data collection with a digital solution that will allow for real-time data collection and aggregation and disaggregation by numerous social determinants of health. This effort has also provided for revision of the existing legislation that will allow for digital interventions in other segments that will further strengthen data collection and communication at PHC level and higher levels of care. </p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Macedonia
