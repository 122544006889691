const BtnNav = ({setShowNav}) => {
  // RETURNING
  return (
    <button
      onClick={() => setShowNav(false)}
      className={`
        icon-close 
        text-2xl text-white
        hover:text-main_blue focus:outline-none
        inline-block self-end
        ani03s`
      }
    ></button>
  )
}
// Export
export default BtnNav
