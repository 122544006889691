const Albania = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health professionals</li>
                <li>Teachers/pupils</li>
                <li>Social workers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Health workers from 3 regions of the country were provided PPE (masks, gloves, gowns, coveralls, goggles, face shields).</li>
                <li>Pupils from the most vulnerable population groups were provided with cloth masks.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>Prioritization of the uninterrupted delivery of the maternal, newborn, and child health services in the context of the pandemic, with the provision of technical and supply assistance for continued breastfeeding, implementation of IPC measures, continuous monitoring of young child nutrition and feeding practices, and medical equipment for the care and treatment of sick newborns.</li>
                <li>Support of the Institute of Public Health and the regional departments of health nationwide to consolidate the web-based data collection system for child nutrition monitoring for children under 5 years of age and adjustment in the context of COVID-19. Health providers in all Albania regions are using a standardized data collection system to monitor child growth and IYCF practices and flag out any emerging risks for child nutrition status in the context of COVID-19.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>n/a</td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Albania
