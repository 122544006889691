// Import Image
import Image from './../../images/moldova.jpg'

// Component
const Moldova = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>With USAID financial support, UNICEF procured WASH and hygiene supplies aimed at ensuring the continuity of essential health services and of COVID- response of the COVID19 Center established in the first weeks of the pandemic. Furthermore, UNICEF supported 1,300 Primary Health centers in the Country with WASH supplies, items for managing medical waste, medical-grade sanitizers, waste bags.</p>
        <p>Additionally, supplies (sanitizers, soap, hygiene and cleaning products) have been provided to: a) 41 Youth Friendly Health Centers (YFHC) nationwide, providing PHC services for adolescents and youth, especially those at risk; b) 12 maternity hospitals providing perinatal care services to mothers and newborns; c) the Municipal Pediatric Hospital for Contagious Diseases; d) 12 residential schools and institutions for children, including for children with disabilities; e) and to 225 vulnerable families benefitting from early intervention services for young children with developmental delays and disabilities. </p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Moldova
