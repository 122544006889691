import './App.css';
// IMPORT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// IMPORT BACKGROUND
import Background from './images/back.jpg'
// IMPORT COMPONENTES
import Header1 from './components/Header1'
import Header2 from './components/Header2'
import Social from './components/Social'
import GoTop from './components/GoTop'
// IMPORT PAGES
import Home from './pages/Home'
import Context from './pages/Context/Context'
import Elements from './pages/Elements/Elements'
import Achievements from './pages/Achievements/Achievements'
import Success from './pages/Success/Success'
import Challenges from './pages/Challenges/Challenges'
import Lessons from './pages/Lessons/Lessons'

// APP
function App() {
  
  return (
    <Router>
      <div 
        className="
          w-screen h-screen
          flex flex-col
          bg-no-repeat bg-cover bg-right-top"
          style={{backgroundImage: 'url(' + Background + ')'}}
      >
        <Switch>
          {/* HOME */}
          <Route path="/" exact>
            <Header1 />
          </Route>
          <Route>
            <Header2 />
          </Route>
        </Switch>
        <div className={`h-full w-full`}>
          <Switch>
            {/* HOME */}
            <Route path="/" exact>
              <Home />
            </Route>
            {/* CONTEXT AND THE SCOPE */}
            <Route path="/context-and-the-scope">
              <Context />
            </Route>
            {/* ELEMENTS OF HEALTH AND NUTRITION RESPONSE TO COVID-19 */}
            <Route path="/elements-of-unicef-health-and-nutrition-response-to-covid-19">
              <Elements />
              <GoTop />
            </Route>
            {/* ACHIEVEMENTS AND PROGRESS */}
            <Route path="/achievements-and-progress">
              <Achievements />
              <GoTop />
            </Route>
            {/* SUCCESS STORIES */}
            <Route path="/success-stories">
              <Success />
              <GoTop />
            </Route>
            {/* CHALLENGES AND THE WAY FORWARD */}
            <Route path="/challenges-and-the-way-forward">
              <Challenges />
              <GoTop />
            </Route>
            {/* LESSONS LEARNED */}
            <Route path="/lessons-learned">
              <Lessons />
            </Route>
          </Switch>
        </div>
        <Social />
      </div>
    </Router>
  );
}

export default App;
