import React from 'react'
import PropTypes from 'prop-types'
// COMPONENT
const PageTitle = ({title}) => {
	return (
		<div className="py-1 flex flex-col">
			<div className="pb-2">
				<h1 className="text-main_blue text-main_blue text-3xl xs:text-3xl noColumnBreak font-bold flex-grow">
					{ title }
				</h1>
				<span className="block h-1.5 w-16 h-0 bg-txt_black_1 mt-1 mb-2"></span>
			</div>
		</div>
	)
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
