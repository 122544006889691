const Table03_05 = () => {
  return (    
    <table className="allBorders w-full border xs:text-xs text-left mt-16">
      <thead className="text-white bg-main_blue">
        <tr>
            <th className="text-xl">Country Office</th>
            <th className="text-xl">Innovations in HSS in the Health and nutrition response to COVID-19</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Uzbekistan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Croatia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Albania</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kyrgyzstan</td><td>n/a</td>
        </tr>
        <tr>
          <td>North Macedonia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Montenegro</td><td>
            <p>As soon as the COVID-19 crisis started, on behalf of the UN system in the country, UNICEF began supporting the national authorities with regular nationally representative opinion polls to inform the decision-making process on the development of new measures and to help monitor their perception and implementation. The opinion polls were also used for planning and monitoring, and evaluating ongoing Risk Communication and Community Engagement (RCCE) efforts. This helped the RCCE to be more data-based, as the questions were agreed with different partners – Government, IPH, Clinical Centre, WHO - depending on the needs for different information through time.</p>
            <p>COVID-19 opinion polls are available at https://www.unicef.org/montenegro/en/public-opinion-polls-about-coronavirus.</p>
            <p>As the COVID-19 pandemic was followed by an infodemic, UNICEF Montenegro, young reporters, volunteers who act as the key spokespersons of our globally awarded media literacy campaign started fact-checking news on COVID-19. Their analyses were published on the UNICEF Montenegro website in Montenegrin, English, and Albanian, and these web pages have been the most popular throughout the entire 2020. Also, their fact-checking analysis was published by Montenegrin media. Young reporters spoke live in Montenegrin media about the ways to do fact-checking in order to raise people's media literacy and help them critically assess all media contents. They invited them to send them news for fact-checking, which happened through UNICEF Montenegro social media. So, this was an example of two-way, interactive communication with audiences for busting myths regarding COVID-19. </p>
            <p>COVID-19 fact-checking webpage https://www.unicef.org/montenegro/en/young-reporters-fact-checking-covid-19-information.</p>
</td>
        </tr>
        <tr>
          <td>Tajikistan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Armenia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Moldova</td><td>n/a</td>
        </tr>
        <tr>
          <td>Ukraine</td><td>Combination of WASH and IPC directions with the joint purpose to ensure safe environment into health care facilities and during home visits.</td>
        </tr>
        <tr>
          <td>Turkmenistan</td><td>CO advocacy in using SMS for sharing COVID prevention messages was successful. Messages were distributed throughout the country.</td>
        </tr>
        <tr>
          <td>Bosnia and Herzegovina</td><td>n/a</td>
        </tr>
        <tr>
          <td>Serbia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Belarus</td><td>n/a</td>
        </tr>
        <tr>
          <td>Turkey</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kosovo</td><td>n/a</td>
        </tr>
        <tr>
          <td>Georgia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Azerbaijan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Bulgaria CO</td><td>n/a</td>
        </tr>
        <tr>
          <td>Romania</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kazakhstan</td><td>To improve nutrition status and access to WASH in schools, UNICEF, jointly with WHO and MoH conducted COSI {'&'} WASH surveys in all regions and capacitated local service providers. </td>
        </tr>
      </tbody>
    
    </table>
  ) 
}
export default Table03_05