// Import Image
import Image from './../../images/bosnia.jpg'

// Component
const Bosnia  = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>UNICEF responded instantly to the COVID-19 pandemic with critical hygiene supplies, personal protective equipment, technical assistance and financial support for infection prevention and control, cold chain improvement, risk communication, and catch-up for routine immunization with a particular focus on vulnerable groups such as Roma children. UNICEF has a critical role in deploying COVID-19 vaccines for BiH through the COVAX mechanism and was selected as procurement coordinator for BiH. A new multi-sectoral 2020-2025 Early Childhood Development Strategy for the Federation of Bosnia and Herzegovina, previously developed with support from UNICEF, was adopted by the FBiH in November 2020.</p>
        <p>UNICEF CO BiH immediately acted to support the government response to prevent the spread of COVID-19. UNICEF procured and distributed critical hygiene supplies (soap, hand sanitizers, hygiene packages, paper towel) to those most vulnerable and in need, including primary and secondary level health facilities, public health institutes, centres for social welfare, children's homes, detention centers, homes for children with disabilities, kindergartens, refugees and migrants and Roma communities, benefiting at least 80,000 people. Over 1 million masks were distributed to schools, centres for social welfare, Roma communities as well as refugees and migrants.  Over 2,600 people were provided with non-contact thermometers and goggles, which were distributed to health facilities and schools. </p>
        <img className="w-full" src={Image} />
      </div>
    </article>
  ) 
}

export default Bosnia 
