const Achievements1 = () => {
  return (
    <div className="article_content pl-6 pr-10">
      <table className="allBorders w-full text-center border xs:text-xs">
        <thead className="text-white bg-main_blue">
          <tr>
              <th rowspan="2" className="text-xl text-left align-middle">Country</th>
              <th colspan="3" className="text-xl">Protection and Prevention Equipment (PPE)</th>
          </tr>
          <tr>
            <th>Sum of Target</th>
            <th>Number Reached</th>
            <th>Achieved %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Albania</td><td>200</td><td>1059</td><td>530%</td>
          </tr>
          <tr>
            <td>Armenia</td><td>15 000</td><td>15 000</td><td>100 %</td>
          </tr>
          <tr>
            <td>Azerbaijan	</td><td>100	</td><td>100	</td><td>100 %</td>
          </tr>
          <tr>
            <td>Belarus	</td><td>30 000	</td><td>38 000	</td><td>127 %</td>
          </tr>
          <tr>
            <td>Bulgaria	</td><td>3 313	</td><td>3 213	</td><td>97 %</td>
          </tr>
          <tr>
            <td>Croatia	</td><td>2 000	</td><td>1 031	</td><td>52 %</td>
          </tr>
          <tr>
            <td>Georgia	</td><td>800	</td><td>1 050	</td><td>131 %</td>
          </tr>
          <tr>
            <td>Greece Partnership Office	</td><td>20 000	</td><td>20 000	</td><td>100 %</td>
          </tr>
          <tr>
            <td>Kosovo	</td><td>10 000	</td><td>9 500	</td><td>95 %</td>
          </tr>
          <tr>
            <td>Macedonia	</td><td>2 000	</td><td>1 700	</td><td>85 %</td>
          </tr>
          <tr>
            <td>Moldova	</td><td>35 000	</td><td>35 000	</td><td>100 %</td>
          </tr>
          <tr>
            <td>Rep of Uzbekistan	</td><td>15 873	</td><td>16 914	</td><td>107 %</td>
          </tr>
          <tr>
            <td>Republic of Kyrgyzstan	</td><td>10 000	</td><td>13 000	</td><td>130 %</td>
          </tr>
          <tr>
            <td>Romania	</td><td>2 200	</td><td>1 716	</td><td>78 %</td>
          </tr>
          <tr>
            <td>Serbia	</td><td>2 650	</td><td>4 967	</td><td>187 %</td>
          </tr>
          <tr>
            <td>Tajikistan	</td><td>3 500	</td><td>4 500	</td><td>129 %</td>
          </tr>
          <tr>
            <td>Ukraine	</td><td>20 000	</td><td>9 965	</td><td>50 %</td>
          </tr>
          
        </tbody>
      </table>
    </div>
  ) 
}
export default Achievements1
