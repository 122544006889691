// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/continuation.jpg'

// Component
const Continuation = () => {

  return (
    <>
      <TitleBox 
        bread = {'CHALLENGES AND THE WAY FORWARD'}
        title={'Continuation essential health and nutrition services'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Lack of data from the MOH to analyze populations access to essential health services and for evidence-based decision making</li>
          <li>Temporary lock downs, and movement restrictions affected health service delivery, especially with marginalized communities</li>
          <li>Relocation and redistribution of health providers from MNCH departments and from community health facilities to tertiary care centers to assist with COVID-19 response, which prevented continuity of care and</li>
          <li>Low motivation, over-loading the staff, and high level of infection among health providers </li>
          <li>Children were not the focus of the epidemic, therefore attention and resources were streamlined to other populations</li>
          <li>Parental fear influenced decrease of usage of preventative services for children </li>
          <li>Increased HCW infections prompted government to close many PHC facilities and ban home visiting</li>
          <li>Anti-science approach to service delivery (lack of guidelines)</li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </>
  ) 
}

export default Continuation
