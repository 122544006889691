const BtnFlagName = ({handler,flag,title}) => {
  return (
    <div className="
      flex flex-row flex-nowrap hover:opacity-90 cursor-pointer ani03s
      border border-main_blue m-2 flex-grow
      min-w-300 max-w-300
    "
    onClick={handler}
    >
      <img className="w-10 m-1" src={flag} />
      <div className="
        flex-grow pl-6 font-bold flex items-center
        bg-main_blue text-xl text-white leading-5
      ">
        {title}
      </div>
    </div>
  ) 
}

export default BtnFlagName
