const Serbia = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health professionals</li>
                <li>Roma health mediators and social workers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>PPE provision included the items most needed that were difficult to obtain on the local market and whose prices were significantly higher. The prioritization included both COVID and non-COVID health institutions (the latter one being dedicated to maternal and child health care) and professionals that for some reasons were not included in the distribution lists (social workers and health mediators).</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>To improve continuity in maternal, neonatal, child health, nutrition, Early Childhood Development (ECD), and early childhood intervention (ECI) services and address their quality, UNICEF supported the Ministry of Health (MoH) in developing national operating procedures for home visiting nurses as well as for delivery, care and breastfeeding during the COVID-19 pandemic.</li>
                <li>Capitalizing on the digital channels, UNICEF invested in the broader dissemination of knowledge and best practices in maternal and child health care and ECD, including on COVID-19 and digital literacy of professionals – reaching 600 pediatricians and home-visiting nurses (HVNs), 80 Roma Health Mediators (RHMs), and more than 200 ECI practitioners, both nationally and regionally, indirectly benefiting more than 15,000 children on a weekly basis.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
              <ul className="bullets">
                <li>New digital exchange and learning platforms have been established for HVNs, ECI practitioners, and pediatricians, enriching them with digital resources and online training modules. The key focuses of capacity building, beyond regular, included topics such as COVID-19 and child health, COVID and immunization, COVID and pregnancy and breastfeeding, COVID and child development. UNICEF also supported remote service provision across various service providers - HVNs, ECI practitioners, and RHMs, who reached more than 47,500 parents through tele/video counseling even during the lockdown period.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
              <ul className="bullets">
                <li>With the overall investment in health system strengthening during COVID-19, UNICEF managed to provide highly needed medical and personal protection equipment (including neonatal intensive care units in four major cities). </li>
                <li>The communication component (campaign and communication activities for parents of young children on relevant health and development issues), including tools for supporting parents (such as Halo baby mobile application), have also been significantly supported since the COVID crises begun.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Serbia
