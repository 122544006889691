// Import Image
import Image from './../../images/croatia.jpg'

// Component
const Croatia = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>In March, among the first in the world, UNICEF Croatia prepared and shared COVID-19: Recommendations on Breastfeeding for Maternities, with accurate information and guidelines for the health professionals to inform response planning, preparedness, and programming. Parallel to that, all relevant guidance issued by the WHO, UNICEF and other organizations were translated to Croatian and shared with the Ministry of Health, the National Crisis Headquarter, Croatian Public Health Institute, maternity hospitals, Croatian Pediatric Association, and all other relevant professional associations.</p>
        <p>The national Human Milk Bank, run by the largest clinical center and supported by UNICEF CO, continued to provide life-saving milk for the most vulnerable premature and sick babies in NICUs, collecting human milk from all parts of Croatia (total of 327 liter) and distributing to NICUs and most critical premature babies.</p>
        <img className="w-full" src={Image} />
      </div>
      <div className="bg-main_blue px-8 py-6 text-3xl text-white text-bold">
        UNICEF Croatia, together with the partners, implemented measures for prevention and control of infectious diseases in the reception facility for asylum seekers, where 450 refugees are currently accommodated, including 120 children.  
      </div>
    </article>
  ) 
}

export default Croatia
