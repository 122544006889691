const BtnFlag = ({handler,flag}) => {
  return (
    <img
      className="min-w-80 max-w-80 m-1 cursor-pointer ani03s hover:opacity-90"
      src={flag}
      onClick={handler}
    />
  ) 
}

export default BtnFlag
