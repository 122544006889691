import { useState } from 'react'
// Page Contents
import Map01 from './Map01'
import Map02 from './Map02'

// Component
const Maps = () => {


  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
  }

  return (
      
     <>
        
        {showMenu1 && <Map01/>}
        {showMenu2 && <Map02/>}

        <p className="pl-6 pr-10 py-2 text-sm">Click to select/deselect</p>
        <div className="w-full flex flex-row items-stretch flex-wrap">
          
          {/* MENU 1 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-main_blue hover:bg-main_blue_90 text-white
          "
            onClick={showMenu1Handler}
          >
            <div className="flex-grow">Number of children vaccinated against Measles in 2020</div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 2 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
          "
            onClick={showMenu2Handler}
          >
            <div className="flex-grow">Title: Number of children vaccinated with DTP3</div>
            {showMenu2 ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
            }
          </div>
          
        </div>

      

    </>
  ) 
}

export default Maps
