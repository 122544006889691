// Import Image
import Image from './../../images/success01.jpg'

// Component
const Uzbekistan = () => {

  return (
    <article className="article_body half_margin pt-10">
      <div className="article_content col2 px-8">
        <p>Mobilization of all resources allowed UNICEF to support the Ministry of Health in first actions on Covid-19. To support coverage at a high level, UNICEF, together with the Agency developed a series of materials and messages for parents about vaccination safety during COVID-19, which were shared as printed materials at the primary level, distributed to the parents, posted in social media, and broadcasted on TV and Radio.</p>
        <p>UNICEF provided intense support to the Agency to plan delivery, actively negotiated delivery plan with the UNICEF SD, and calculated cold chain capacity to avoid overloading. One by one, all vaccines arrived in the country safely and were placed in appropriate conditions. Over Summer, Uzbekistan has received annual stock of almost all required vaccines.</p>
        <p>In addition to that, UNICEF conducted competitive bidding to outsource additional cold chain facility that the Agency may use to store vaccines. The selection of the storage was undertaken jointly by UNICEF and the Agency specialists following high standards of quality for cold chain storage.</p>
        <p>UNICEF also mobilize additional resource for procurement of PPEs for the national immunization program.</p>
        <img className="w-full" src={Image} />
      </div>
      <div className="bg-main_blue px-8 py-6 text-3xl text-white text-bold">
        All these measures have ensured that the immunization coverage has kept a similar level of 2019. 
      </div>
    </article>
  ) 
}

export default Uzbekistan
