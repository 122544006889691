import { useState } from 'react'
// Page Content
import Provision1 from './Provision1'
import Continuation from './Continuation'
import Innovation from './Innovation'
import Provision2 from './Provision2'
import Provision3 from './Provision3'

// Component
const Challenges = () => {

  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  const [showMenu5, setShowMenu5] = useState(false);
  function showMenu1Handler(e) {
    e.preventDefault();
    setShowMenu1(true)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowMenu5(false)
  }
  function showMenu2Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(true)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowMenu5(false)
  }
  function showMenu3Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(true)
    setShowMenu4(false)
    setShowMenu5(false)
  }
  function showMenu4Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(true)
    setShowMenu5(false)
  }
  function showMenu5Handler(e) {
    e.preventDefault();
    setShowMenu1(false)
    setShowMenu2(false)
    setShowMenu3(false)
    setShowMenu4(false)
    setShowMenu5(true)
  }

  return (
    <div className="pages">
      
        {showMenu1 && <Provision1 />}
        {showMenu2 && <Continuation />}
        {showMenu3 && <Innovation />}
        {showMenu4 && <Provision2 />}
        {showMenu5 && <Provision3 />}
       
        <div className="w-full mb-14 flex flex-row items-stretch flex-wrap">

          {/* MENU 1 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-main_blue hover:bg-main_blue_90 text-white
          "
            onClick={showMenu1Handler}
          >
            <div className="flex-grow">Provision of PPE</div>
            {showMenu1 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 2 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-purple_hor_menu hover:bg-purple_hor_menu_90 text-white
          "
            onClick={showMenu2Handler}
          >
            <div className="flex-grow">Continuation of essential health and nutrition services</div>
            {showMenu2 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 3 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-l_green_hor_menu hover:bg-l_green_hor_menu_90 text-white
            "
              onClick={showMenu3Handler}
            >
            <div className="flex-grow">Use of innovation and digital health technology</div>
            {showMenu3 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 4 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-d_green_hor_menu hover:bg-d_green_hor_menu_90 text-white
            "
              onClick={showMenu4Handler}
            >
            <div className="flex-grow">Provision of HSS support</div>
            {showMenu4 ? 
              <span className="icon-circle-filled px-3 text-2xl"></span> :
              <span className="icon-circle-outline px-3 text-2xl"></span>
            }
          </div>
          {/* MENU 5 */}
          <div className="
            flex flex-row flex-nowrap flex-grow min-h-100p min-w-200
            pl-4 py-2 w-1/5 items-center ani03s cursor-pointer
            bg-black hover:bg-black_90 text-white
            "
              onClick={showMenu5Handler}
            >
            <div className="flex-grow">Provision of support to regulate school nutrition programs in the context of the pandemic</div>
            {showMenu5 ? 
              <span className="icon-circle-filled pl-3 pr-8 text-2xl"></span> :
              <span className="icon-circle-outline pl-3 pr-8 text-2xl"></span>
            }
          </div>
          
        </div>

    </div>
  ) 
}

export default Challenges
