const Macedonia = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Health workers in COVID-19 treatment centers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Support the national health care system with 10 ventilators, 5,000 testing kits, 5,000 gloves, 10,000 masks, 20 pulse oximeters, 1,200 bottles of hand sanitizers, and 1,700 liters of surface disinfectants.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>Digitalization of the reporting and recording system for home visitors will significantly improve data collection that is now paper-based and aggregated reports published with more than a year delay. Digital data collection will provide real-time data collection and aggregation and disaggregation by numerous social determinants of health for pregnant women and mothers with newborns.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>n/a</td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Macedonia
