const Azerbaijan = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>NGO partners and volunteers </li>
                <li>Social workers </li>
                <li>Trainers and participants during face-to-face training on IPC and COVID-19 management.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Risk communication (development and distribution of printed materials, social media channels, TV);</li>
                <li>Advocacy for maintenance of essential MCH services amid pandemic, technical support to the Government in emergency response;</li>
                <li>Technical and financial support to the provision of alternative platforms for parenting counseling (on ECD, breastfeeding, child health, and nutrition during quarantine and home confinement) through social network pages on Facebook and Instagram (live counseling sessions provided by health professionals on various topics; Q{'&'}A sessions for parents);</li>
                <li>Training/capacity-building on Infection prevention and control for MCH personnel and specific recommendations related to COVID in pediatric and maternal health settings (including BF during COVID) through face-to-face, online training sessions, and webinars (based on the adaptation of UNICEF RO training package) - covered 950 HCW; </li>
                <li>PROACT Training project with a target to train 2,000 HCW on IPC, risk communication, COVID patients' management at hospitals through adaptation of training curriculum of Ege University, development of national trainer capacities, and establishment of 12 training centers across the country;</li>
                <li>Technical support for preparation to COVID-19 vaccine deployment - all relevant technical recommendations and guidelines were communicated with the Government, translated into Azerbaijani; cold chain assessment and sizing tool were used to calculate cold chain supply needs; vaccination strategy was revised, and technical inputs provided.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
              <ul className="bullets">
                <li>Provision of technical support to all relevant technical and decision-making level professionals to smoothen the process and prevent possible complications with governmental reforms in such a challenging period of double emergency in the country.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Azerbaijan
