const Table03_04 = () => {
  return (    
    <table className="allBorders w-full border xs:text-xs text-left mt-16">
      <thead className="text-white bg-main_blue">
        <tr>
            <th className="text-xl">Country Office</th>
            <th className="text-xl">Innovations and digital technology in health and nutrition response</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Uzbekistan</td><td>
            <p>UNICEF has procured 45 sets of web-conferencing equipment for the national and regional perinatal and pediatric centers and sanitary-epidemiological service centers. Upon delivery and installation of the equipment, all facilities will be connected to a network for online cooperation and virtual learning during and after COVID-19. </p>
            <p>UNICEF is also closely working with the Tashkent Pediatric Medical Institute to create a package of online training on contemporary issues in MCH for the in-service professional development of healthcare providers, including a course on IPC and C-19 prevention and control. The training modules include Hand Hygiene, Routine Practices, and the Chain of Transmission. The course on IPC will be a self-study course for workplace training. Each module is enhanced with photos, videos, and graphics to illustrate major concepts and practical skills such as proper hand washing procedures and donning and doffing PPE. Pre- and post-tests, case situations, and multiple-choice quizzes will help to measure the quality of skills development and knowledge transfer.</p>
            <p>Once the online training is endorsed by the national authorities, it will be loaded to the Learning Management System (MOODLE) of the Tashkent Pediatric Medical Institute. Strong emphasis will be put on developing staff's digital competencies and facilitating their education in a virtual environment. All healthcare workers across the country will be able to access this training for free.</p>
            <p>It is expected that online training on IPC will provide equitable, convenient, and effective access to standardized, up-to-date information on best practices in infection control and prevention and fill the gaps in current training resources.</p>
          </td>
        </tr>
        <tr>
          <td>Croatia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Albania</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kyrgyzstan</td><td>WhatsApp communication's operationalization as an alternative way of child consultation during such conditions as limited access to the family doctors, high prevalence of the coronavirus cases, and enforced lockdowns on a level of the communities made a breakthrough the outdated stereotypes and system constraints. It justified the effectiveness of the Telemedicine services to ensure access of the families to the essential health services. </td>
        </tr>
        <tr>
          <td>North Macedonia</td><td>Digitalization of the reporting and recording system for home visitors will significantly improve data collection that is now paper-based and aggregated reports published with more than one year delay. Digital data collection will provide real-time data collection and aggregation and disaggregation by numerous social determinants of health for pregnant women and mothers with newborns.</td>
        </tr>
        <tr>
          <td>Montenegro</td><td>
          <p>Parenting App under development at ECARO level, adaptations being made at CO level to enable use at the national level.</p>
          <p>Online supervision sessions organized for home visiting nurses (using online platforms – although this was planned even before COVID-19).</p>
</td>
        </tr>
        <tr>
          <td>Tajikistan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Armenia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Moldova</td><td>
          <p>UNICEF Moldova Office, together with the WHO office and UNICEF Office in Romania, supported the translation of the Romanian language HealthBudy chatbot that can be used by Romanian language speakers to be informed about COVID19. </p>
          <p>Ureport used Viber's social media platform to reach more young people with information and learn from them about the significant challenges during 2020.</p>
          <p>The Upartners platform was used to respond to young people's queries related to mental health issues and reproductive health issues. Around 800 young people were reached with information based on their needs.</p>
</td>
        </tr>
        <tr>
          <td>Ukraine</td><td>CO is starting to work on a mobile application/online platform with essential medical information for families, health workers on the main ECD chapters, COVID. The platform will be piloted in several regions with possible extensions to the national level.</td>
        </tr>
        <tr>
          <td>Turkmenistan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Bosnia and Herzegovina</td><td>
          <p>UNICEF with Ministries of Health and PHIs continued promoting an innovative 'Immunization App' during 2019. The app provides parents/caregivers with information on when the next vaccination is due and the closest health centre to make an appointment.</p>
          <p>UNICEF and PHIs also partnered with a local NGO to operate a dedicated blog to provide parents/caregivers with evidence-based facts on vaccines in user-friendly language. Content created included the Kids TV puppet show explaining COVID-19 prevention measures, including hygiene and healthy nutrition.</p>
</td>
        </tr>
        <tr>
          <td>Serbia</td><td>n/a</td>
        </tr>
        <tr>
          <td>Belarus</td><td>To ensure that quality youth-friendly health services (YFHS) provide effective COVID-19 responses, the CO partnered with leading NGO experts in building the capacity of health care on the effective use of the online tools for adolescents counseling.  An online course, "The online management of YFHCs" (available through the link: https://edu-unicef.online/) was developed and facilitated by the CO hired expert, being completed by all 57 youth-friendly health clinics (YFHCs) coordinators and other staff.
•	Collaborating with the RVC, YFHCs, and CSOs, two online courses – "Volunteers of Health" and "Peer Counselling Techniques" - were developed and tested among 52 young people, who gained skills and received psychological supervision to conduct online peer-to-peer information. These courses are available on the online platform (https://edu-unicef.online/). Two more supervised groups will be trained in 2021, after which the courses will also be available for self-learning. 
•	16 trained adolescents provided online outreach to their peers on COVID-19 through a developed chatbot as well as YFHC activities. Adolescents living with HIV also provided specialized peer consultations on facts about HIV and COVID-19.</td>
        </tr>
        <tr>
          <td>Turkey</td><td>n/a</td>
        </tr>
        <tr>
          <td>Kosovo</td><td>Following the restrictions related to COVID-19, the UPHV continued to provide online and in-person essential health counseling.
•	UNICEF organized a social media campaign, 'WhileAtHome" which reached more than 170,000 people, among others, promoting health and nutrition.
•	A specific social media campaign on breastfeeding and nutrition during the COVID-19 pandemic was developed and launched during the breastfeeding week. The social media campaign aimed to provide evidence-based information to the parents on the importance and the management of breastfeeding and nourishment during pregnancy, especially during the COVID-19 pandemic and reached around 50,000 viewers.</td>
        </tr>
        <tr>
          <td>Georgia</td><td>NICEF supported the introduction of a new service delivery model - Shared Medical Appointments (SMA) to ensure the continuity of antenatal care services in the context of the COVID-19 pandemic. All pregnant women in Georgia (App. 26,000) have been invited for the remote antenatal consultations delivered simultaneously to the groups of 20-25 pregnant. The shared care format enabled pregnant to spend more time with their clinician and to interact with one another, both of which showed to be calming in the context of social isolation. 
•	One of the biggest challenges that the pandemic posed was information sharing with the health service providers. For the updated protocols to reach all doctors across the country, especially the doctors residing in rural areas, has been a challenge. UNICEF supported the Government in providing a centralized communications platform to all rural doctors across the country. The platform includes one main server, which hosts various cloud-based video conferencing tools for group training, has a possibility of sending SMS notifications simultaneously; collecting all interactions with doctors in a video format; giving assignments to doctors and monitoring the progress; allowing sharing of training materials in a written as well as in multimedia formats.
•	UNICEF, in partnership with Czech Caritas continues to deliver the training via the platform to the rural doctors, but most importantly, the teleconsultations have also been adopted. The rural doctors are given the space to discuss with the specialists concrete cases from their practice related to the training topic and collect the expert's recommendations. The knowledge gained allows the rural doctors to practice teleconferencing – remotely interact with the patients, including parents/caregivers of rural children. 
•	Moreover, more than 1000 ambulatories in the country – currently with no internet connection – are getting connected to the net with UNICEF support. UNICEF is committed to cover the internet infrastructure installation cost, as well as 6 months of internet service cost. </td>
        </tr>
        <tr>
          <td>Azerbaijan</td><td>n/a</td>
        </tr>
        <tr>
          <td>Bulgaria CO</td><td>n/a</td>
        </tr>
        <tr>
          <td>Romania</td><td>Support the National Electronic Vaccination Registry for national vaccination campaign and flu vaccine pilot. </td>
        </tr>
        <tr>
          <td>Kazakhstan</td><td>With UNICEF's support, the National Public Health Center (NPHC) under the Ministry of Health (MoH) adopted and planned to introduce NUTRIDASH into the national monitoring system.</td>
        </tr>
      </tbody>
    
    </table>
  ) 
}
export default Table03_04