// Import Components
import HeaderCont from './HeaderCont'
// Component
const Header = () => {
  return (
    <header className="inline w-full min-h-180 max-h-180 relative">
      <HeaderCont />
    </header>
  )
}
// Export
export default Header
