const Azerbaijan  = () => {

  return (
    <article className="article_body small_margin pt-10">
      <div className="article_content col2 px-8">
        <p>The success of 2020 was the establishment of a partnership with the new government agency TABIB, which now operates all public healthcare providers. The agency was very new, with limited staff and limited capacity of available personnel, absence of experience in health system management and work with healthcare providers, limited knowledge about UN, UNICEF and our mandates and fields of expertise and potential for collaboration. And all this was complicated with ongoing challenges with healthcare reforms, the unexpected burden of COVID-19 emergency and lack of support and cooperation with MoH. There was a long way in explaining the new agency mandate and role of UNICEF, areas where we can collaborate and support them to go through all these challenges, building trust to organization and its technical expertise, capacity building of their staff on certain children and maternal health issues and system's element to be strengthened. Now we have finally close collaboration in various areas, firm trust in our recommendations and technical guidance.</p>
      </div>
    </article>
  ) 
}

export default Azerbaijan 
