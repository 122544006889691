// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/provision2.jpg'

// Component
const Provision2 = () => {

  return (
    <>
      <TitleBox
        bread = {'CHALLENGES AND THE WAY FORWARD'}
        title={'Provision of HSS support'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Weak data and statistics hindering the evidence-based decision making </li>
          <li>Low technical and skill capacity of the managers to plan, manage and effectively address COVID-19 related challenges by lack of systems thinking and understanding of all bottlenecks</li>
          <li>Focus and interventions were shifted and redirected to COVID-19 response needs</li>
          <li>Dynamicity of epidemiological situation and increasing needs for IPC </li>
          <li>Changes in the political landscape affected sustainability of interventions</li>
          <li>Absence of clear vision and road map for reforming healthcare system</li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </>
  ) 
}

export default Provision2
