// Import Components
import TitleBox from '../../components/TitleBox'
// Import Image
import Image from './../../images/innovation.jpg'

// Component
const Innovation = () => {

  return (
    <>
      <TitleBox
        bread = {'CHALLENGES AND THE WAY FORWARD'}
        itle={'Use of innovation and digital health technology'}
      />
      <article className="article_body noBg col2 px-4">
        <ul className="bullets noColumnBreak">
          <li>Limited access to learning equipment, platforms, capacity building, supportive supervision particularly in rural areas. </li>
          <li>Low digital literacy and technical capacity amongst targeted professional groups and health professionals</li>
          <li>Time constraints due to high workload of health workers</li>
          <li>Lack of a unified platform and low speed of internet hindered the effective organization of e-learning </li>
        </ul>
        <img className="w-full noColumnBreak" src={Image} />
      </article>
    </>
  ) 
}

export default Innovation
