const Belarus = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Front line health care providers</li>
                <li>Volunteers of Red Cross</li>
                <li>The staff of the residential care institutions for children </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Provision of diverse PPE and critical hygiene items to the most vulnerable groups such as frontline health workers, medical and social care staff in residential care institutions for children, and volunteers of the Red Cross. </li>
                <li>Delivery of 121927 FFP3 respirators, 150000 pairs of gloves, 10000 gowns of high density, 15000 shoe covers, 267000 face masks, 42000 liters of hand sanitizer, 140 oxygen concentrators, 1000 distance thermometers.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>To ensure that quality youth-friendly health services (YFHS) provide effective COVID-19 responses, the CO partnered with leading NGO experts in building the capacity of health care on the effective use of the online tools for adolescents counseling.  An online course, "The online management of YFHCs" (available through the link: https://edu-unicef.online/), was developed and facilitated by the CO hired expert, being completed by all 57 youth-friendly health clinics (YFHCs) coordinators and other staff.</li>
                <li>Collaborating with the RVC, YFHCs, and CSOs, two online courses – "Volunteers of Health" and "Peer Counselling Techniques" - were developed and tested among 52 young people, who gained skills and received psychological supervision to conduct online peer-to-peer information. These courses are available on the online platform (https://edu-unicef.online/). Two more supervised groups will be trained in 2021, after which the courses will also be available for self-learning. </li>
                <li>16 trained adolescents provided online outreach to their peers on COVID-19 through a developed chatbot as well as YFHC activities. Adolescents living with HIV also provided specialized peer consultations on facts about HIV and COVID-19.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
            <ul className="bullets">
                <li>Redirection of activities and resources to deliver immediate infection prevention and control (IPC) interventions in the healthcare systems. Partnering with key medical academies and facilities, the Office applied a system strengthening approach to enhance frontline medical workers' knowledge and skills in responding to COVID. Particular attention was paid to frontline healthcare workers whose facilities were reprofiled to manage COVID, providing training webinars and information materials to 183 professionals on diagnosis approaches, treatment, and prophylactics of COVID-19. Expansion of training sessions and information online reached over 16,000 healthcare providers and others by end-December and continues to serve as information support. All webinars' records are available on BelMAPO YouTube (https://www.youtube.com/user/belmapoby)</li>
                <li>To reduce manifestations of psychological distress, the Office supported a variety of emergency psychological assistance for frontline healthcare providers. Approximately 1016 psychologists and healthcare professionals enhanced their capacities to provide psychological assistance to specialists working in the COVID-19 pandemic frontline. Individual psychological consultations have been made available for healthcare workers and pedagogues of residential care institutions for children, with 346 individual consultations and 1534 people receiving distance relaxation and psycho-therapeutic techniques by end-December.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Belarus
