const Kosovo = () => {

  return (
    
    <div className="bg-bg_gray p-6 pt-28">
      <div className="overflow-x-auto">  
        <table className="table-fixed bg-white min-w-900 a_borders">
          <tr>
            <td rowspan="2" className="bg-white min-w-200 font-bold">Protection and Prevention Equipment (PPE)</td>
            <td className="min-w-200">Target group</td>
            <td className="min-w-500">
              <ul className="bullets">
                <li>Front line health workers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Key features of supporting PPE</td>
            <td>
              <ul className="bullets">
                <li>Delivery of 24.4 tons of Personal Protective Equipment distributed to over 300 health facilities which reached 9,500 front line health workers (73 percent).</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Continuation of essential health and nutrition services including, immunization and IYCF</td>
            <td>Key features of supporting MOHs for the continuation of essential health and nutrition services</td>
            <td>
              <ul className="bullets">
                <li>UNICEF provided support to the NIPH and MoH in establishing 60 temporary immunization centers outside of health facilities. As a result, 7,500 children were vaccinated within the first month at these centers. </li>
                <li>To further mitigate the impact of COVID-19 disruptions to immunization services for the most vulnerable children, UNICEF, in collaboration with the NIPH, conducted door-to-door visits to households to identify children who have missed their immunizations.</li>
                <li>Through the engagement of 78 volunteers, 2,478 children were visited, of which 970 were identified with incomplete records. Separate catch-up vaccination sessions were organized for these children in their respective municipalities. </li>
                <li>Through UNICEF support to MoH and NIPH, a Cold Chain Equipment (CCE) assessment was conducted to facilitate strengthening the vaccine cold chain system for the routine immunization programme.</li>
                <li>Consolidation and expansion of the programme by enhanced skills of 781 home visiting nurses reaching up to 18,017 children aged 0-3 years and 3,434 pregnant women.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Innovations and use of digital technology</td>
            <td>Key features on Innovations and digital technology</td>
            <td>
            <ul className="bullets">
                <li>Provision online and in-person essential health counseling.</li>
                <li>Organization of a social media campaign 'WhileAtHome", which reached more than 170,000 people, among others, promoting health and nutrition.</li>
                <li>A specific social media campaign on breastfeeding and nutrition during the COVID-19 pandemic was developed and launched during the breastfeeding week. The social media campaign aimed to provide evidence-based information to the parents on the importance and the management of breastfeeding and nourishment during pregnancy, especially during the COVID-19 pandemic, and reached around 50,000 viewers.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Health System strengthening for COVID-19</td>
            <td>Key features of Health System strengthening for COVID-19</td>
            <td>
            <ul className="bullets">
                <li>UNICEF supported the Vaccine Cold Chain Assessment. The assessment findings identified gaps and recommendations to improve the vaccine cold chain system and capacities for the regular immunization programme and COVID-19 vaccine, which have resulted in the Ministry of Finance commitment in the amount of Eur 2.3 million for Cold Chain Equipment.</li>
                <li>Essential support was also extended through the provision of 40 Oxygen Concentrators to Primary Health Care facilities. This supply support was complemented with the training of family doctors in 38 Municipalities on the technical use and functioning of oxygen concentrators. At the same time, the Ministry of Health has provided support in the adoption and translation of the clinical guideline for the use of concentrators.</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>  
    </div>

  ) 
}

export default Kosovo
